import { Instance, Instances, useGLTF } from '@react-three/drei'
import React, { Suspense, useEffect, useRef } from 'react'
import tetra from './tetra.glb'
import { DoubleSide } from 'three'
import { useThree } from '@react-three/fiber'

import treeArray from './tetra.json'
import lowTreeArray from './tetra-low.json'
import { useQualitySwitch } from '../../stores'
/**
 * Random Tree Set Creator Functions
 */

/**
 * Wrapper component for Suspense
 */

export default React.memo(function InstancedTreeComponent() {
  return (
    <Suspense fallback={null}>
      <InstancedTrees />
    </Suspense>
  )
})

/**
 * Wrapper component for loading Tree GLB and initialize Instances
 */
function InstancedTrees() {
  const checkQuality = useQualitySwitch()
  const glb = useGLTF(tetra)
  const { gl } = useThree()

  useEffect(() => {
    // gl.sortObjects = false
    // console.log(glb.materials.Material)
    glb.materials.beach_bboulders.side = DoubleSide
    // glb.materials.Material.depthWrite = false
    // glb.materials.Material.depthTest = false
  }, [glb])

  return (
    <Instances
      range={1000}
      material={glb.materials.beach_bboulders}
      geometry={glb.nodes.Tetrapod.geometry}
    >
      {checkQuality.quality === 'low-res' &&
        lowTreeArray.map((data, index) => {
          if (data.position[1] > -15 && index != 500 && index != 499 && index != 505)
            return <InstancedTree key={index} {...data} />
        })}
      {checkQuality.quality === 'high-res' &&
        treeArray.map((data, index) => {
          if (data.position[1] > -15 && index != 500 && index != 499 && index != 505)
            return <InstancedTree key={index} {...data} />
        })}
    </Instances>
  )
}

/**
 * Wrapper component for an Instance
 */
function InstancedTree(props) {
  const ref = useRef()

  // useEffect(() => {
  //   // console.log(ref)
  // })

  // useFrame(() => {
  //   // group.current.rotation.y += 0.01 * RandomNumber() // Animation
  // })
  return (
    <group renderOrder={999 - props.key} {...props}>
      <Instance ref={ref} />
    </group>
  )
}
