export const _enableDesinger = false //use this

// dont edit
export const designerEnabled = _enableDesinger && process.env.NODE_ENV === 'development'
export const baseUrl =
  process.env.NODE_ENV === 'development' && designerEnabled ? 'http://localhost:3001/' : '/'

export const getFileUrl = (url) => {
  return process.env.NODE_ENV === 'development' && designerEnabled
    ? baseUrl + 'file/' + encodeURIComponent(url)
    : baseUrl + url
}
// .split('\\') .join('/')
export const roomFormElements = {
  name: 'string',
  // model: {},
  title: 'string',
  subTitle: 'string',
  desc: 'textarea',
  suboptions: '[]',
}
export const hotspotFormElements = {
  name: 'string',
  // name: '',
  title: 'string',
  desc: 'textarea',
  benefits: 'textarea',
  applications: 'textarea',
  hotspotImage: 'file',
  keyValPairs: 'textarea',
  suboptions: '[]',
  type: 'options',
}
