import { StyleSheet, css } from 'aphrodite'
import React, { useRef } from 'react'
import { pulse } from 'react-animations'
import { animated as a } from '@react-spring/web'
import { useMeasure } from 'react-use'
export const HotspotHover = (props) => {
  const [ref, { width }] = useMeasure()
  const { hover } = StyleSheet.create({
    hover: {
      ':hover': {
        width: `calc(${width}px + 1vw)`,
        opacity: 1,
      },
    },
  })
  return (
    <a.div {...props} className={css(styles.container)}>
      <p className={css(styles.p, hover)}>
        <span style={{ margin: '-0vh 2vh 0vh 4vh' }}>{props.title}</span>
      </p>
      <p ref={ref} className={css(styles.pauto)}>
        <span style={{ margin: '-0vh 2vh 0vh 4vh' }}>{props.title}</span>
      </p>
      <svg
        className={css(styles.hp)}
        // width="33"
        height="100%"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9912 31.3613C25.4136 31.3613 32.2412 24.5337 32.2412 16.1113C32.2412 7.68899 25.4136 0.861328 16.9912 0.861328C8.56887 0.861328 1.74121 7.68899 1.74121 16.1113C1.74121 24.5337 8.56887 31.3613 16.9912 31.3613Z"
          fill="#BF3A71"
          fillOpacity="0.7"
          stroke="#F2F2F2"
          strokeWidth="1.5"
        />
        <path
          d="M19.587 8.52149C18.909 8.52134 18.2396 8.67442 17.629 8.9693C17.0185 9.26418 16.4824 9.69323 16.0609 10.2244C15.6395 10.7556 15.3435 11.3751 15.1951 12.0367C15.0467 12.6984 15.0498 13.385 15.204 14.0452L9.52165 19.9346C9.11977 20.3609 8.9014 20.928 8.91357 21.5137C8.92574 22.0994 9.16748 22.6569 9.58673 23.0661C10.006 23.4753 10.5692 23.7035 11.155 23.7015C11.7409 23.6995 12.3025 23.4674 12.7189 23.0554L18.3518 17.3494C19.0705 17.554 19.8289 17.5764 20.5586 17.4147C21.2882 17.253 21.9661 16.9122 22.5311 16.4231C23.0961 15.934 23.5305 15.3119 23.7951 14.613C24.0596 13.9141 24.146 13.1603 24.0465 12.4196C24.0327 12.3155 23.99 12.2173 23.9233 12.1362C23.8565 12.0551 23.7684 11.9943 23.6689 11.9607C23.5693 11.9271 23.4624 11.922 23.3601 11.946C23.2579 11.9701 23.1644 12.0223 23.0903 12.0967L20.712 14.4761L18.1324 11.8965L20.5118 9.51711C20.5862 9.443 20.6384 9.34952 20.6625 9.24726C20.6865 9.14501 20.6815 9.03806 20.6479 8.93853C20.6143 8.839 20.5535 8.75088 20.4723 8.68413C20.3912 8.61738 20.293 8.57468 20.1889 8.56086C19.989 8.53435 19.7876 8.5212 19.5859 8.52149H19.587Z"
          fill="white"
        />
      </svg>
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    // display: 'flex',
    height: '5vh',

    position: 'absolute',
  },
  hp: {
    animationName: pulse,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    pointerEvents: 'none',
  },
  p: {
    position: 'absolute',
    // left: '1vw',
    padding: '0.2vh',
    paddingLeft: '1.0vw',
    top: 0,
    overflow: 'hidden',
    fontSize: '1.9vh',
    fontWeight: 500,
    lineHeight: '5vh',
    margin: 0,
    marginTop: '-0.2vh',
    fontFamily: 'Work Sans',
    opacity: 0,
    width: '5vh',
    boxSizing: 'border-box',
    border: 'black solid 1px',
    backgroundColor: 'white',
    borderRadius: '3vh',
    transitionDuration: '500ms',
    whiteSpace: 'nowrap',
    // ':hover': {
    //   width: '15vw',
    //   opacity: 1,
    // },
  },
  pauto: {
    position: 'absolute',
    // left: '1vw',
    pointerEvents: 'none',
    opacity: 0,
    padding: '0.2vh',
    paddingLeft: '1.0vw',
    paddingRight: '0.7vw',
    top: 0,
    fontSize: '1.9vh',
    fontWeight: 500,
    lineHeight: '5vh',
    margin: 0,
    marginTop: '-0.2vh',
    fontFamily: 'Work Sans',
    width: 'auto',
    boxSizing: 'border-box',
    border: 'black solid 1px',
    backgroundColor: 'white',
    borderRadius: '3vh',
    transitionDuration: '500ms',
    whiteSpace: 'nowrap',
    // ':hover': {
    //   width: '15vw',
    //   opacity: 1,
    // },
  },
})
