import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useAppState } from '../stores'
import { NavBar } from './fullscreen/components/NavBar'
import { CategoryPage } from './fullscreen/CategoryPage'
import { ScrollManager } from './fullscreen/components/ScrollManager'
import { SingleProductCard } from './fullscreen/components/SingleProductCard'
import { Logo } from './assets/Logo'
import { Gradient } from './fullscreen/components/Gradient'
import { ProductList } from './fullscreen/components/ProductList'
import { SubCategoryName } from './fullscreen/components/SubCategoryName'
import { GoToAllProducts } from './fullscreen/components/GoToAllProducts'
import { Seal } from './assets/Seal'
import { CameraOverrideButton } from './assets/CameraOverrideButton'
import { ARButton } from './assets/ARButton'
import { SmartServicesRender } from './fullscreen/components/SmartServicesRender'

export const UIManager = () => {
  const { currentContent } = useAppState()

  return (
    <>
      <Gradient />
      {/* <MobileImage currentContent={currentContent} /> */}
      <div className={css(styles.container)}>
        <NavBar />
        {/* <CategoriesList /> */}
        <CategoryPage />
        <ScrollManager />
        <SingleProductCard currentContent={currentContent} className={styles.none} />
        <ProductList />
        <Logo className={css(styles.logo)} />
        <SubCategoryName />
        <GoToAllProducts className={css(styles.viewAll)} />
        <Seal className={css(styles.seal)} />
        <CameraOverrideButton />
        <ARButton />
        <SmartServicesRender />

        {/* <ContactUs /> */}
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: 10,
  },
  logo: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 310,
  },
  none: {},
  viewAll: {
    position: 'absolute',
    right: '1.5%',
    bottom: '2%',
    pointerEvents: 'auto',
    cursor: 'pointer',
    height: '5vh',
  },
  seal: {
    position: 'absolute',
    top: '14vh',
    right: '1vw',
    height: '10vh',
  },
})
