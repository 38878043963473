export const Color_Black = 0x000000
export const Color_White = '#ffffff'
export const Color_BlackTransparent = '#000000A1'
export const Color_WhiteTransparent = 'rgba(255,255,255,0.92)'
export const Color_Gray = '#54717c'
export const Color_AutoVRseBlue = '#325d75'
export const Color_AutoVRseDarkBlue = '#193043'
export const Color_AutoVRseLightBlue = '#46738c'

export const font = {
  normal: 'Work Sans',
}

export const colors = {
  trueBlue: '#001965',
  white: '#ffffff',
  lightGrey: '#595959',
  godrejGreen: '#97BF55',
}
