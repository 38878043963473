import { Instance, Instances, useGLTF } from '@react-three/drei'
import React, { Suspense, useEffect, useRef } from 'react'
import tree from './tree.glb'
import { DoubleSide } from 'three'

import treeArray from './trees.json'
import lowTreeArray from './tree-low.json'
import { useQualitySwitch } from '../../stores'
/**
 * Random Tree Set Creator Functions
 */

/**
 * Wrapper component for Suspense
 */

export default React.memo(function InstancedTreeComponent() {
  return (
    <Suspense fallback={null}>
      <InstancedTrees />
    </Suspense>
  )
})

/**
 * Wrapper component for loading Tree GLB and initialize Instances
 */
function InstancedTrees() {
  const checkQuality = useQualitySwitch()
  const glb = useGLTF(tree)

  useEffect(() => {
    // gl.sortObjects = false
    // console.log(glb.materials.Material)
    glb.materials.Material.side = DoubleSide
    // glb.materials.Material.depthWrite = false
    // glb.materials.Material.depthTest = false
  }, [glb])

  return (
    <Instances range={400} material={glb.materials.Material} geometry={glb.nodes['9'].geometry}>
      {checkQuality.quality === 'low-res' &&
        lowTreeArray.map((data, index) => <InstancedTree key={index} {...data} />)}
      {checkQuality.quality === 'high-res' &&
        treeArray.map((data, index) => <InstancedTree key={index} {...data} />)}
    </Instances>
  )
}

/**
 * Wrapper component for an Instance
 */
function InstancedTree(props) {
  const ref = useRef()

  // useEffect(() => {
  // console.log(ref)
  // })

  // useFrame(() => {
  //   // group.current.rotation.y += 0.01 * RandomNumber() // Animation
  // })
  return (
    <group renderOrder={999 - props.key} {...props}>
      <Instance ref={ref} />
    </group>
  )
}
