import { useSpring } from '@react-spring/core'
import React, { useMemo, useState, useEffect } from 'react'
import { useHud, useMaterials } from '../../../stores'
import { StyleSheet, css } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { getFileUrl } from '../../../settings'
import { Accordion, KeyValue } from '../../Accordion/Accordion'
import { CloseIcon } from '../../assets/CloseIcon'
import { useLocation } from 'wouter'
import { colors } from '../../../../../theme'
import { MaterialChangeUi } from './MaterialChangeUi'
import { MaterialChangeScreens, MaterialChangeText, prefabContent } from '../../../../../constants'
import { AppStore, GooglePlay } from '../../assets/ExternalLinks'

const SmartServiceButtons = () => {
  const { activeService, setActiveService } = useHud()
  return (
    <div className={css(styles.smartServiceSection)}>
      {/* //! Mobile SmartSevice */}
      <div
        className={css(styles.smartServiceText)}
        style={{
          background: activeService === 'mobile' ? 'rgba(255, 255, 255, 0.1)' : 'none',
          display: 'flex',
          width: ' 100%',
        }}
        onClick={() => {
          setActiveService('mobile')
        }}
      >
        <div
          style={{
            display: activeService === 'mobile' ? 'block' : 'none',
            borderLeft: '0.5vh solid #97BF55',
            margin: 0,
            height: '100%',
          }}
        ></div>
        <div style={{ paddingLeft: '4.4vh', position: 'absolute' }}>Godrej GO TUFF APP</div>
      </div>

      {/* //! Van SmartSevice */}
      <div
        className={css(styles.smartServiceText)}
        style={{
          background: activeService === 'van' ? 'rgba(255, 255, 255, 0.1)' : 'none',
          display: 'flex',
        }}
        onClick={() => {
          setActiveService('van')
        }}
      >
        <div
          style={{
            display: activeService === 'van' ? 'block' : 'none',
            borderLeft: '0.5vh solid #97BF55',
            margin: 0,
            height: '100%',
          }}
        ></div>
        <div style={{ paddingLeft: '4.4vh', position: 'absolute' }}>Concrete Testing Squad</div>
      </div>
      <hr
        className={css(styles.line)}
        style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}
      ></hr>
    </div>
  )
}
export const SingleProductCard = ({
  currentContent,
  noAnim,
  unselect,
  style,
  className = {},
  showAnyway = false,
  imgText,
}) => {
  const [hide, setHide] = useState(false)
  const [location, setLocation] = useLocation()
  const { tuff_blocks } = useMaterials()
  const { setEnquiryForm } = useHud()
  const { activeService } = useHud()
  // console.log('cont', currentContent)
  // const { currentContent } = useAppState()

  // only the hotspots with that are not sub categories will have desc
  const condition = useMemo(
    () =>
      showAnyway ||
      (currentContent.roomName &&
        (currentContent.type === 'cameraonly' ||
          currentContent.type === 'options' ||
          currentContent.type === 'normal')),
    [currentContent]
  )
  const spring = useSpring(
    noAnim
      ? {}
      : {
          transform: condition && !hide ? 'translateX(0%)' : 'translateX(-130%)',
        }
  )
  useEffect(() => {
    if (condition) setHide(false)
    // console.log('current content: ' + currentContent.name)
  }, [condition])
  // console.log('current content: ' + currentContent.name)
  return (
    <a.div
      id="productCard"
      style={{
        ...spring,
        ...style,
        padding:
          currentContent.name === 'tuff_pavers' || currentContent.name === 'tuff_blocks'
            ? '2vh 0vh 8vh 0vh'
            : '2vh 4.45vh 8vh 4.45vh',
      }}
      className={css(styles.container, className)}
    >
      <div
        style={{
          width: '100%',
          background: currentContent.hotspotImage?.url ? 'rgba(255, 255, 255, 1)' : 'none',
          position: currentContent.hotspotImage?.url ? 'absolute' : 'relative',
          left: 0,
          top: 0,
          height: currentContent.hotspotImage?.url ? '9.8vh' : 'auto',
          paddingLeft: currentContent.hotspotImage?.url ? '4.4vh' : 0,
          paddingRight: currentContent.hotspotImage?.url ? '4.4vh' : 0,
        }}
      >
        {!imgText && (
          <CloseIcon
            onClick={() => {
              // setHide(true)

              unselect && unselect()
              let newLoc = location.split('/').filter((x) => x != '')
              newLoc = newLoc.slice(0, newLoc.length - 1).join('/')
              setLocation('/' + newLoc)
            }}
            style={{
              marginTop:
                currentContent.name === 'bench1' ||
                currentContent.name === 'bench2' ||
                currentContent.name === 'embankment' ||
                currentContent.name === 'boxculvert'
                  ? '-0.5vh'
                  : '2.8vh',
            }}
            className={css(styles.close)}
          />
        )}
        {currentContent.hotspotImage?.url ? (
          <img
            alt={imgText}
            className={css(styles.image)}
            style={{
              height:
                currentContent.name === 'light_tuff' || currentContent.name === 'poro_tuff'
                  ? '7vh'
                  : '11.8vh',
              marginTop:
                currentContent.name === 'light_tuff' || currentContent.name === 'poro_tuff'
                  ? '1vh'
                  : '-1vh',
            }}
            src={getFileUrl(currentContent.hotspotImage.url)}
          />
        ) : (
          imgText && <p className={css(styles.imgText)}>{imgText?.toUpperCase()}</p>
        )}
        {/* //! Prefab subcategories subTitle */}
        {(currentContent.name === 'bench1' ||
          currentContent.name === 'bench2' ||
          currentContent.name === 'embankment' ||
          currentContent.name === 'boxculvert') && (
          <p className={css(styles.imgText)}>{prefabContent.bench1.subTitle.toUpperCase()}</p>
        )}
        <hr
          className={css(styles.line)}
          style={{ display: currentContent.hotspotImage?.url ? 'none' : 'block' }}
        ></hr>
      </div>

      {currentContent.name === 'tuff_pavers' || currentContent.name === 'tuff_blocks' ? (
        // ||
        // currentContent.name === 'tuffblocks_aac'
        <>
          <MaterialChangeUi options={MaterialChangeScreens[currentContent.name]} />
          <hr className={css(styles.line)}></hr>
          <div
            style={{ marginTop: '1vh', color: 'rgba(255,255,255,0.7)', paddingLeft: '6vh' }}
            className={css(styles.scroll, styles.desc)}
          >
            {currentContent.name === 'tuff_blocks' ? (
              <>
                <p style={{ fontSize: '1.75vh' }}>
                  {MaterialChangeText[currentContent.name][tuff_blocks].heading}
                </p>
                {/* {console.log('material: ' + MaterialChangeText[currentContent.name][tuff_blocks])} */}
                <div className={css(styles.description)}>
                  {MaterialChangeText[currentContent.name][tuff_blocks].desc
                    ?.split(/\r?\n/)
                    .map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                </div>
                <KeyValue pairs={MaterialChangeText[currentContent.name][tuff_blocks].keyVal} />
              </>
            ) : (
              <>
                <p style={{ fontSize: '1.75vh' }}>
                  {MaterialChangeText[currentContent.name].heading}
                </p>
                {/* {console.log('material: ' + MaterialChangeText[currentContent.name][tuff_blocks])} */}
                <div className={css(styles.description)}>
                  {MaterialChangeText[currentContent.name].desc
                    ?.split(/\r?\n/)
                    .map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                </div>
                <KeyValue pairs={MaterialChangeText[currentContent.name].keyVal} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {/* show mobile Image buttons */}
          {currentContent.name === 'Smart_Services' && <SmartServiceButtons />}
          <div
            className={css(styles.scroll)}
            style={{
              marginTop: currentContent.hotspotImage?.url
                ? '8vh'
                : currentContent.name === 'Smart_Services'
                ? '13.5vh'
                : 0,
            }}
          >
            {currentContent.name === 'tuffblocks_aac' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    // background: 'red',
                    flexWrap: 'nowrap',
                    margin: 0,
                    padding: 0,
                    // position: 'absolute',
                    // left: 0,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      // flexDirection: 'column',
                      width: '98%',
                      // background: 'blue',
                      flexWrap: 'nowrap',
                      margin: 0,
                      padding: 0,
                      // position: 'absolute',
                      // left: 0,
                    }}
                  >
                    <div
                      style={{
                        height: '7vh',
                        width: '7vh',
                        marginRight: '3vh',
                      }}
                    >
                      <img
                        style={{ height: '100%', width: '100%' }}
                        src={MaterialChangeText[currentContent.name].src}
                      />
                    </div>
                    {/* <div style={{ marginBottom: '1.5vh' }}> */}
                    <h3 className={css(styles.title)} style={{ marginBottom: '1.5vh' }}>
                      {MaterialChangeText[currentContent.name].title}
                    </h3>
                    {/* </div> */}
                  </div>
                </div>
                <div
                  // className={css(styles.line)}
                  style={{
                    color: 'rgba(255,255,255,0.2)',
                    border: '0.04vh solid rgba(255,255,255,0.2)',
                    height: '0vh',
                    width: '100%',
                    position: 'absolute',
                    left: 0,
                    transform: 'scaleX(1)',
                  }}
                  // className={css(styles.line)}
                ></div>
              </>
            )}
            {/* //! Prefab subcategories title */}
            {(currentContent.name === 'bench1' ||
              currentContent.name === 'bench2' ||
              currentContent.name === 'embankment') && (
              <h3
                className={css(styles.title)}
                style={{
                  marginBottom: '1.5vh',
                }}
              >
                {prefabContent.bench1.name}
              </h3>
            )}
            {currentContent.name === 'boxculvert' && (
              <h3
                className={css(styles.title)}
                style={{
                  marginBottom: '1.5vh',
                }}
              >
                {prefabContent.boxCulvert.name}
              </h3>
            )}
            {/* //! Remove smart service condition to add title */}
            <h3
              className={css(styles.title)}
              style={{
                marginBottom: '1.5vh',
                display:
                  currentContent.name != 'tuffblocks_aac' && currentContent.name != 'Smart_Services'
                    ? 'block'
                    : 'none',
              }}
            >
              {currentContent.title}
            </h3>
            {/* //! SmartService Description Section */}

            {currentContent.name != 'Smart_Services' ? (
              <div
                className={css(styles.description)}
                style={{
                  lineHeight: '120%',
                  marginTop: currentContent.name === 'tuffblocks_aac' ? '2vh' : 0,
                }}
              >
                {currentContent.desc?.split(/\r?\n/).map((text) => (
                  <>{text}</>
                ))}
              </div>
            ) : (
              <div
                className={css(styles.description)}
                style={{
                  lineHeight: '150%',
                  marginTop: '4vh',
                }}
              >
                {activeService === 'mobile'
                  ? 'The Godrej Construction Materials “Godrej GO TUFF” app eases the process of placing an order and also enables you to plan your concrete-related activities better. Convenient features like real-time delivery tracking ensures transparency to optimize and manage every step of the construction material supply from order to delivery. It helps you to seamlessly streamline your concrete workflow with other construction activities anytime, anywhere from any device.'
                  : 'The Concrete Testing Squad provides unique and convenient on-site concrete trials eliminating the need to travel, thereby saving your time. On-site quality testing expedites the approval process by providing a concrete testing report at your doorstep for faster turnaround. It provides an opportunity for Project Management Consultants, Contractors, Developers, and other stakeholders to be a part of the process and instantly view the concrete testing results.'}
                {/* {currentContent.desc?.split(/\r?\n/).map((text) => (
                  <>{text}</>
                ))} */}
              </div>
            )}

            {/* //! Prefab subcategories desc */}
            {(currentContent.name === 'bench1' ||
              currentContent.name === 'bench2' ||
              currentContent.name === 'embankment') && (
              <div
                className={css(styles.description)}
                style={{
                  lineHeight: '120%',
                  marginTop: currentContent.name === 'tuffblocks_aac' ? '2vh' : 0,
                }}
              >
                {prefabContent.bench1.desc}
              </div>
            )}
            {currentContent.name === 'boxculvert' && (
              <div
                className={css(styles.description)}
                style={{
                  lineHeight: '150%',
                  marginTop: currentContent.name === 'tuffblocks_aac' ? '2vh' : 0,
                }}
              >
                {prefabContent.bench1.desc}
              </div>
            )}
            {/* {currentContent.name === 'boxculvert' && (
              <h3
                className={css(styles.title)}
                style={{
                  marginBottom: '1.5vh',
                }}
              >
                {prefabContent.boxCulvert.desc}
              </h3>
            )} */}
            {currentContent.name === 'tuffblocks_aac' && (
              <>
                <div style={{ marginTop: '0vh' }}>
                  <p style={{ fontSize: '1.75vh' }}>
                    {MaterialChangeText[currentContent.name].heading}
                  </p>
                  <div className={css(styles.description)}>
                    {MaterialChangeText[currentContent.name].desc
                      ?.split(/\r?\n/)
                      .map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                  </div>
                  {/* <KeyValue pairs={MaterialChangeText[currentContent.name].keyVal} /> */}
                </div>
              </>
            )}
            {/* <p className={css(styles.description)}>{currentContent.desc}</p> */}
            {currentContent.benefits && (
              <Accordion title="Benefits" content={currentContent.benefits} />
            )}
            {currentContent.applications && (
              <Accordion title="Applications" content={currentContent.applications} />
            )}
            <div style={{ marginTop: '2vh' }}>
              {currentContent.keyValPairs && <KeyValue pairs={currentContent.keyValPairs} />}
            </div>
            {/* <ProductList /> */}
            {currentContent.name === 'Smart_Services' && activeService === 'mobile' && (
              <div className={css(styles.appbuttons)}>
                <GooglePlay />
                <AppStore />
              </div>
            )}
            {/* <p className={css(styles.footerTitle)}>
              Want to Know More? <span className={css(styles.touch)}>Get in Touch</span>
            </p> */}
            <div className={css(styles.footerTitle)} onClick={() => setEnquiryForm(true)}>
              Get in Touch
            </div>
          </div>
        </>
      )}
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    color: '#F2F2F2',
    position: 'absolute',
    fontFamily: 'Work Sans',
    textAlign: 'left',
    left: 0,
    top: '8.5vh',
    overflow: 'hidden',
    height: '91.5vh',
    color: 'white',
    width: '25vw',
    // padding: '2vh 4.45vh 8vh 4.45vh',
    margin: '0vh 0vh 1vh 0vh',
    backgroundColor: 'rgba(0,0,0,0.85)',
    zIndex: 50,
  },
  smartServiceSection: {
    position: 'absolute',
    left: 0,
    top: '7vh',
    // paddingLeft: '4.4vh',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // background: 'red',
    color: '#ffffff',
  },
  smartServiceText: {
    // paddingBottom: '4%',
    // paddingTop: '4%',
    // paddingLeft: '4.4vh',
    // paddingRight: 0,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: '2.3vh',
    height: '6.9vh',
    // justifyContent: 'space-evenly',
    alignItems: ' center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  image: {
    // height: '11.8vh',
    // marginTop: '-1vh',
    // marginBottom: '0.5vh',
  },
  title: {
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: '2.5vh',
    marginTop: '2vh',
  },
  description: {
    fontSize: '1.8vh',
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  footerTitle: {
    fontSize: '1.9vh',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    textAlign: 'center',
    color: '#3D3D3D',
    background: '#F2F2F2',
    width: '15vh',
    height: 'auto',
    paddingTop: '2%',
    paddingBottom: '2%',
    borderRadius: '2vw',
    position: 'absolute',
    bottom: '2.5vh',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  touch: {
    textDecoration: 'underline',
  },
  // line: {
  //   margin: 0,
  // },
  footerline: {
    fontSize: '12px',
    color: '#f2f2f2',
  },
  scroll: {
    pointerEvents: 'auto',
    height: '100%',
    // backgroundColor: 'red',
    // overflowX: 'visible',
    borderRadius: 4,
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '2.4vh',
      height: '2.4vh',
    },
  },
  close: {
    height: '3vh',
    // marginTop: '2.8vh',
    float: 'right',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  line: {
    margin: 0,
    transform: 'scale(2,0.2)',
    color: 'white',
    // height: '0.04vh',
    // border: 0,
    // borderWidth: 0,
  },
  imgText: {
    color: colors.godrejGreen,
    margin: 0,
    marginTop: '0.5vh',
    marginBottom: '2vh',
    fontSize: '1.8vh',
  },
  appbuttons: {
    display: 'flex',
    margin: 0,
    marginTop: '2vh',
  },
})
