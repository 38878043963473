import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useAppState, useHud } from '../../../stores'
import { useLocation } from 'wouter'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'

function CategoriesList({ state }) {
  const { allRooms } = useAppState()
  const [location, setLocation] = useLocation()
  const { setBypass } = useHud()
  // const [suboption, setSuboption] = useState([])

  let filteredUsers = !state.roomName
    ? state.hotspots.filter((x) => {
        return x.type === 'subcat' || x.type === 'products'
      })
    : null

  const { opacity } = useSpring({
    opacity:
      state.name === 'Ready_Mix_Concrete' || state.name === 'Walling_And_Paving_Solutions' ? 1 : 0,
  })

  const startSpring = useSpring({
    marginTop: state.name == 'base' ? '4.5vh' : '-48.5vh',
    opacity: state.name == 'base' ? 1 : 0,
    // delay: 800,
    config: config.slow,
  })

  return (
    <>
      <a.div
        className={css(styles.container)}
        style={{
          opacity,
          display:
            state.name === 'Ready_Mix_Concrete' || state.name === 'Walling_And_Paving_Solutions'
              ? 'flex'
              : 'none',
        }}
      >
        {allRooms.map((category, index) => {
          return (
            <>
              {category.name === 'Ready_Mix_Concrete' ||
                (category.name === 'Walling_And_Paving_Solutions' &&
                  filteredUsers != null &&
                  filteredUsers.map((subcategory, index) => {
                    return (
                      <>
                        {subcategory.title != '' && (
                          <div
                            key={index}
                            className={css(styles.items)}
                            style={{
                              borderRight: `${
                                index === filteredUsers.length - 1
                                  ? 0
                                  : subcategory.name === 'recycled_concrete'
                                  ? 0
                                  : '0.2vh solid rgba(255, 255, 255, 0.5)'
                              }`,
                              paddingLeft: state.name === 'Ready_Mix_Concrete' ? '3%' : '4%',
                              display:
                                subcategory.name != 'recycled_concrete_products' &&
                                subcategory.name != 'walling_and_paving_products'
                                  ? 'flex'
                                  : 'none',
                            }}
                            onClick={() => {
                              let split = location.split('/').filter((x) => x !== '')
                              let subcat01 = '/' + split + '/' + subcategory.name
                              setLocation(subcat01)
                              // setSuboption(subcategory.suboptions)
                              setBypass(false)
                            }}
                          >
                            {subcategory.title != '' ? subcategory.title.toUpperCase() : null}
                          </div>
                        )}
                      </>
                    )
                  }))}
            </>
          )
        })}
      </a.div>
    </>
  )
}

export default CategoriesList

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    // justifyContent: 'space-between',
    position: 'absolute',
    // left: '7%',
    marginTop: '18vh',
    // top: '2%',
    // display: 'flex',
    alignItems: 'center',
    // flexWrap: 'wrap',
    // width: 1040,
    background: 'rgba(0, 0, 0, 0.6)',
    minHeight: '8.5vh',
    color: 'white',
    padding: '1.3vh',
  },
  items: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // paddingTop: '1.5%',
    // paddingLeft: '2%',
    // paddingRight: '2%',
    // paddingBottom: '1.5%',
    padding: '1.5vh 2.2vh',
    // flex: '1 1 auto',

    // margin: 5,
    // padding: '2%',
    // height: '1.5%',
    // padding: '5%',
    // pointerEvents: 'auto',
    // padding: '1.5%',
    color: 'white',
    // background: 'blue',

    textAlign: 'center',
    // verticalAlign: 'middle',
    fontSize: '1.8vh',
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    pointerEvents: 'auto',
    // flexBasis: '60%',

    // paddingRight: '2%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  categoryContainer: {
    display: 'flex',
    // justifyContent: 'space-between',
    position: 'absolute',
    left: '7%',
    // transform: 'translateY(20vh)',
    // top: '2%',
    // display: 'flex',
    alignItems: 'center',
    // flexWrap: 'wrap',
    // width: 1040,
    background: 'rgba(0, 0, 0, 0.6)',
    minHeight: '8.5vh',
    color: 'white',
    padding: '1.3vh',
  },
  items01: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // paddingTop: '1.5%',
    // paddingLeft: '2%',
    // paddingRight: '2%',
    // paddingBottom: '1.5%',
    padding: '1.5vh 2.2vh',
    // flex: '1 1 auto',

    // margin: 5,
    // padding: '2%',
    // height: '1.5%',
    // padding: '5%',
    // pointerEvents: 'auto',
    // padding: '1.5%',
    color: 'white',
    // background: 'blue',

    textAlign: 'center',
    // verticalAlign: 'middle',
    fontSize: '1.8vh',
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    // pointerEvents: 'auto',
    // flexBasis: '60%',

    // paddingRight: '2%',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
