import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { useQualitySwitch } from '../stores'
import { a, config, useSpring } from '@react-spring/web'
import { css, StyleSheet } from 'aphrodite'
import LoadingSpinner from './LoadingSpinner'

function QualitySwitch() {
  // const GPUTier = useDetectGPU()
  const qualitySwitch = useQualitySwitch()
  const [location] = useLocation()
  const [fade, setFade] = useState(false)

  function changeQuality() {
    // if(GPUTier.tier < 3) {

    if (qualitySwitch.quality === 'low-res') {
      qualitySwitch.setQuality('high-res')
    } else if (qualitySwitch.quality === 'high-res') {
      qualitySwitch.setQuality('low-res')
    }
    setFade(true)
    // qualitySwitch.setQuality(!qualitySwitch.quality)
    // }
  }

  useEffect(() => {
    setTimeout(() => {
      setFade(false)
    }, 2000)
    // console.log('fade: ' + fade)
  }, [fade])

  const animatedStyle = useSpring({
    opacity: fade ? 2 : 0,
    display: fade ? 'flex' : 'none',
    config: config.gentle,
  })
  return (
    <>
      {/* <Html> */}
      <div
        style={{
          position: 'absolute',
          top: '9vh',
          right: '1.9vh',
          zIndex: 6,
          display: location != 'Sustainability' || location != 'Smart_Services' ? 'block' : 'none',
        }}
      >
        <button
          style={{ pointerEvents: 'auto' }}
          className={css(styles.button)}
          onClick={() => changeQuality()}
        >
          {qualitySwitch.quality === 'low-res'
            ? 'Switch to high resolution'
            : 'Switch to low resolution'}
        </button>
      </div>

      <a.div className={css(styles.fadeOut)} style={animatedStyle}>
        <LoadingSpinner />
        <div className={css(styles.text)}>Please Wait While Changing Quality...</div>
      </a.div>
    </>
  )
}

export default QualitySwitch

const styles = StyleSheet.create({
  button: {
    outline: 'none',
    border: 0,
    background: 'none',
    color: '#ffffff',
    fontSize: '2vh',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    height: 'auto',
    width: 'auto',
    ':hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  lottie: {
    height: 100,
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    transform: 'scale(3.5)',
    position: 'absolute',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 100 / 2.5,
      width: 100 / 2.5,
      transform: 'scale(6)',
      marginLeft: 60,
      marginTop: 0,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
      height: 100 / 3,
      width: 100 / 3,
      transform: 'scale(8)',
      marginLeft: 60,
      marginTop: 0,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
      height: 100 / 2,
      width: 100 / 2,
      transform: 'scale(6)',
      marginLeft: 60,
      marginTop: 0,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)': {
      transform: 'scale(3)',
      marginTop: 12,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)': {
      transform: 'scale(3.5)',
    },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      transform: 'scale(3)',
      marginTop: 12,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      transform: 'scale(3.5)',
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      transform: 'scale(3)',
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      transform: 'scale(4)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(4.5)',
      height: 150,
      width: 150,
      marginTop: 0,
    },
  },
  collider: {
    // height:100,
    width: 100,

    // top:0,
    textAlign: 'center',
    pointerEvents: 'auto',
    // backgroundColor: 'black',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 40,
      width: '100%',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
      // marginLeft: 'auto',
      height: 33,
      width: '100%',
      // top: 90
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
      // marginTop: -50,
      height: 50,
      width: '100%',
      // top: 36
    },
    '@media(min-width: 2560px)': {
      // transform: 'scale(6)',
      height: 150,
      width: 150,
    },
  },
  fadeOut: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    zIndex: 1500,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#000000',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    textAlign: 'center',
  },
  text: {
    color: 'white',
    fontSize: 40,
    // paddingTop: 260,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
  },
})
