import { OrbitControls } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
// import { useControls } from 'leva'
import React, { useEffect, useRef, useState } from 'react'
import { designerEnabled } from '../../settings'
import { useCustomCamera, useDesigner, useLandscapeMode } from '../../stores'
import { TweenManager } from './TweenManager'
// import { useThree } from 'react-three-fiber'
// import * as THREE from 'three'
// import { useCopyToClipboard } from 'react-use'

export const Camera = React.memo(({ name }) => {
  // const { camera } = useThree()
  const controls = useRef()
  const { camera } = useThree()
  const ref = useRef()
  const selected = useRef()
  const smallRef = useRef()
  const { selectedHp } = useDesigner()
  // const [state, copy] = useCopyToClipboard()
  // const { setHotspot, copyView } = useControls({
  //   setHotspot: button(() => useCamera.setHotspotPosition({ ...controls.current.target })),
  //   copyView: button(() => copy(getString(camera.position, controls.current.target, camera.fov))),
  // })
  // const { camera } = useThree()
  // const { fov, lockZoom } = useControls({
  //   fov: { value: 75 },
  //   lockZoom: { value: true },
  // })
  const { objectBounds, ...useCamera } = useCustomCamera()
  const [suspendTween, set] = useState(true)

  useEffect(() => {
    useCamera.setCamera(camera)
    useCamera.setControls(controls.current)
    set(false)
    camera.fov = 30
  }, [])

  useEffect(() => {
    // if (selected.current != null && selected.current)
    if (selectedHp && selectedHp.position && selected.current && designerEnabled)
      selected.current?.position.set(...Object.values(JSON.parse(selectedHp.position)))
  }, [selected, selectedHp])

  return (
    <>
      <OrbitControls
        // screenSpacePanning={!designerEnabled}
        screenSpacePanning={designerEnabled}
        // rotateSpeed={useCamera.isTweening?0:1}
        enableZoom={designerEnabled && !lockZoom}
        // enablePan={name === 'nndc'}
        enableRotate={designerEnabled}
        panSpeed={designerEnabled}
        enableDamping={false}
        // mouseButtons={{
        //   LEFT: THREE.MOUSE.RIGHT,
        //   RIGHT: THREE.MOUSE.LEFT,
        // }}
        // onChange={onControlsChange}
        zoomSpeed={1.8}
        ref={controls}
      />
      {/* <Sphere transp ref={ref} /> */}
      {designerEnabled && (
        <>
          <mesh ref={ref}>
            <sphereBufferGeometry args={[1, 50, 50]} />
            <meshBasicMaterial transparent opacity={0.5} />
          </mesh>
          <mesh ref={smallRef}>
            <sphereBufferGeometry args={[0.1, 50, 50]} />
            <meshBasicMaterial color="blue" />
          </mesh>
          <mesh position={[0, -400, 0]} ref={selected}>
            <sphereBufferGeometry args={[1, 50, 50]} />
            <meshBasicMaterial color="green" transparent opacity={0.5} />
          </mesh>
          {/* <CustomControls controls={controls} /> */}
          {/* <CameraComponent /> */}
        </>
      )}
      {!suspendTween && camera && controls.current && <TweenManager />}
    </>
  )
})
