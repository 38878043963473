import { AdaptiveDpr } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'

function CustomDPR({ qualityMode }) {
  const { gl } = useThree()
  const [quality, setQuality] = useState(false)

  useEffect(() => {
    let tom1 = setTimeout(() => {
      gl.setPixelRatio(0.8)
    }, 800)
    let tom = setTimeout(() => {
      setQuality(qualityMode)
    }, 1200)

    return function cleanup() {
      clearTimeout(tom)
      clearTimeout(tom1)
    }
  }, [qualityMode])

  useEffect(() => {
    gl.setPixelRatio(0.1)
  }, [])
  return <>{quality && <AdaptiveDpr />}</>
}

export default CustomDPR
