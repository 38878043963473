import React, { useEffect } from 'react'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { useAppState, useHud } from '../../../stores'
import { StyleSheet, css } from 'aphrodite'

export const SmartServicesRender = () => {
  const {
    currentContent: { name },
  } = useAppState()
  const { activeService } = useHud()
  const { setCurrentProduct } = useHud()

  const opacity = useSpring({
    // config: config.slow,
    opacity: name === 'Smart_Services' && activeService === 'van' ? 1 : 0,
    // delay: name === 'Smart_Services' || name === 'Sustainability' ? 1900 : 0,

    // config: config.slow,
  })
  const sustain = useSpring({
    opacity: name === 'Sustainability' ? 1 : 0,
    delay: 2000,
  })

  const sustainImag = useSpring({
    opacity: name === 'Sustainability' ? 1 : 0,
    // delay: 2000,
  })

  const MobileOpacity = useSpring({
    opacity: name === 'Smart_Services' && activeService === 'mobile' ? 1 : 0,
  })

  const catOpacity = useSpring({
    opacity: name === 'Smart_Services' ? 1 : 0,
    delay: 2000,
  })

  useEffect(() => {
    if (name === 'Smart_Services') {
      if (activeService === 'mobile') {
        setCurrentProduct('Godrej Go TUFF App')
      } else {
        setCurrentProduct('Concrete Testing Squad')
      }
    }
  }, [activeService, name === 'Smart_Services'])

  return (
    <>
      <a.div style={catOpacity}>
        <a.div style={opacity} className={css(styles.vanContainer)}>
          <img width="100%" src={'/images/SmartServices.png'} />
        </a.div>
        <a.div style={MobileOpacity} className={css(styles.smartphoneContainer)}>
          <div
            style={{
              width: '50%',
              height: 'auto',
              marginLeft: '35%',
              marginRight: 'auto',
              // background: 'red',
              marginTop: '15.5vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <div
              style={{
                width: '48.77vh',
                height: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                // background: 'blue',
                // marginTop: '8.5vh',
              }}
            >
              <img src="/images/GoTuffScreen1.png" style={{ width: '100%', height: '100%' }} />
            </div>
            <div
              style={{
                width: '48.77vh',
                height: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                // background: 'red',
                // marginTop: '8.5vh',
              }}
            >
              <img src="/images/GoTuffScreen2.png" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </a.div>
      </a.div>
      {/* //! Sustainibility */}
      <a.div style={sustain}>
        <a.div className={css(styles.sustainContainer)} style={sustainImag}>
          <img className={css(styles.image)} src={'/images/Sustainability.png'} />
        </a.div>
      </a.div>
    </>
  )
}

const styles = StyleSheet.create({
  sustainContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 40,
    backgroundColor: '#aaa4ae',
    textAlign: 'center',
    // transformOrigin: 'bottom center',
    // transform: 'scaleY(2) scaleX(1.5)',
  },
  image: {
    width: '100%',
    height: '100%',
    transition: 'transform 1s',
    transform: 'scale(1.2)',
    transformOrigin: 'bottom right',
  },
  smartphoneContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    // zIndex: 40,
    background: 'linear-gradient(180deg, #C0D997 0%, #FFFFFF 22.92%)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  vanContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    // zIndex: 40,
    backgroundColor: '#aaa4ae',
    textAlign: 'center',
  },
})
