import create from 'zustand'
import { baseUrl, designerEnabled } from './settings'
import room from './rooms.json'
import data from '../../data.json'
import axios from 'axios'
import { Vector3 } from 'three'

export const initialParams = { page: 'homepage', hotspot: '-1', location: '-1' }
export const useCustomHistory = create((set) => ({
  // params: { page: 'homepage', hotspot: -1, location: -1 },
  params: {},
  setParams: (obj) =>
    set((state) => {
      Object.keys(obj).forEach((key) => (state.params[key] = obj[key]))
    }),
}))

export const useAppState = create((set) => ({
  allRooms: [],
  // roomIdx: -1,
  room: null,
  hotspotIdx: -1,
  currentContent: {},
  allProductsInfo: [],
  // page: '',
  // hotspot: '-1',
  // location: '-1',
  videoPlaying: false,
  previousContent: {},
  // setPage: (page) => {
  //   set((state) => ({ ...state, page }))
  // },
  // setLocation: (location) => set((state) => ({ ...state, location })),
  // setHotspot: (hotspot) => set((state) => ({ ...state, hotspot })),
  setAllProductsInfo: (list) => set((state) => (state.allProductsInfo = list)),
  setVideoPlaying: (bol) => set((state) => (state.videoPlaying = bol)),
  setAll: (obj) =>
    set((state) => {
      Object.keys(obj).forEach((key) => {
        if (key === 'currentContent') state.previousContent = state[key]
        state[key] = obj[key]
      })
    }),
  fetchRooms: async () => {
    if (designerEnabled) {
      try {
        const response = await axios.get(baseUrl + 'rooms')
        set((state) => ({ ...state, allRooms: response.data }))
      } catch (err) {
        console.log(err)
      }
    } else {
      set((state) => ({ ...state, allRooms: data }))
    }
  },
  setAllRooms: (rooms) => set((state) => (state.allRooms = rooms)),
  setPreviousAppState: (obj) =>
    set((state) => (state.previousAppState = JSON.parse(JSON.stringify(obj)))),
}))

export const useLoadingManager = create((set) => ({
  items: [],
  itemCount: 0,
  progress: 0,
  fadeIn: true,
  itemsDone: [],
  isDone: false,
  addMultipleItems: (itemNames) => [...itemNames], //Do we really care about the item names
  addItem: (itemName) => set((state) => [...state.items, itemName]),
  // addItems: (count) => set((state) => (state.itemCount = count)),
  setProgress: (prog) => set((state) => (state.progress = prog)),
  setIsDone: (prog) => set((bool) => (state.isDone = bool)),
  reportDone: (itemName) =>
    set((state) => {
      let temp = [...state.items]
      temp.splice(temp.indexOf(itemName), 1)
      state.items = temp
      state.itemsDone = [...state.itemsDone, itemName]
      state.isDone = true
      // if (state.progress != 100) {
      // console.log('item done:' + state.itemsDone.length + 'items' + temp.length)
      // }
    }),
  setFadeIn: (bol) => set((state) => (state.fadeIn = bol)),
}))
export const useCameraOverride = create((set) => ({
  view: {},
  defaultView: {},
  setView: (view, defaultView) =>
    set((state) => {
      state.view = view
      state.defaultView = defaultView
    }),
}))
export const useCustomCamera = create((set) => ({
  camera: null,
  controls: null,
  isTweening: false,
  objectBounds: null,
  hotspotPosition: new Vector3(0, 0, 0),
  setObjectBounds: (bounds) => set((state) => (state.objectBounds = bounds)),
  setCamera: (cam) => set((state) => (state.camera = cam)),
  setControls: (cont) => set((state) => (state.controls = cont)),
  setisTweening: (bol) => set((state) => (state.isTweening = bol)),
  setHotspotPosition: (pos) =>
    set((state) => state.hotspotPosition.set(...Object.values(state.controls.target))),
  bindtoLookAt: (bol) => {
    if (bol) {
      set((state) => {
        state.hotspotPosition = state.controls.target
      })
    } else {
      set((state) => (state.hotspotPosition = { ...state.controls.target }))
    }
  },
}))

export const useDesigner = create((set) => ({
  selectedHp: '',
  setSelectedHp: (name) => set((state) => (state.selectedHp = name)),
}))

export const useMaterials = create((set) => ({
  tuff_pavers: 0,
  tuff_blocks: 0,
  setTuffBlocks: (idx) => set((state) => (state.tuff_blocks = idx)),
  setTuffPavers: (idx) => set((state) => (state.tuff_pavers = idx)),
}))

export const useQualitySwitch = create((set) => ({
  quality: 'low-res',
  setQuality: (string) => set((state) => (state.quality = string)),
}))

export const useHud = create((set) => ({
  activeService: 'mobile',
  bypass: false,
  enquiryForm: false,
  currentProduct: 'Enviro Tuff',
  setCurrentProduct: (string) => set((state) => (state.currentProduct = string)),
  setEnquiryForm: (bool) => set((state) => (state.enquiryForm = bool)),
  setBypass: (bool) => set((state) => (state.bypass = bool)),
  setActiveService: (string) => set((state) => (state.activeService = string)),
}))

export const useLandscapeMode = create((set) => ({
  status: false,
  disabled: false,
  setDisabled: (bool) => set({ disabled: bool }),
  setStatus: (state) => set({ status: state }),
}))
