import play from './googleplay.png'
import appstore from './appstore.png'

export const GooglePlay = (props) => {
  return <img style={{ cursor: 'pointer', height: '7vh' }} {...props} src={play} height="100%" />
}

export const AppStore = (props) => {
  return (
    <img
      style={{ cursor: 'pointer', transform: 'translateY(1.1vh)', height: '4.8vh' }}
      {...props}
      height="100%"
      src={appstore}
    />
  )
}
