import { Plane, Sky } from '@react-three/drei'
import React from 'react'

function WaterIOS() {
  return (
    <>
      <Plane args={[1000, 1000]} rotation={[-Math.PI / 2, 0, 0]} position={[100, -10, -10]}>
        <meshBasicMaterial attach="material" color="#024B86" transparent opacity={0.8} />
      </Plane>
      <Sky sunPosition={[100, 100, -100]} distance={1000} />
    </>
  )
}

export default WaterIOS
