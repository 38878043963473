import { useGLTF } from '@react-three/drei'
import React, { useEffect, Suspense, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLoadingManager, useQualitySwitch } from '../stores'
import { useThree } from '@react-three/fiber'
import { Lights } from '../core/3d/Lights'
import { MaterialChanger } from './MaterialChanger'

export const Room = ({ onLoaderDone, setActions, name, ...props }) => {
  const checkQuality = useQualitySwitch()
  // checkQuality.quality === 'low-res'
  const object = useGLTF('/glb/mobileGLB/model.gltf')
  const desktopGlb = useGLTF('/glb/desktopGLB/model.gltf')
  const loader = useLoadingManager()
  const { gl } = useThree()
  // const copiedScene = useMemo(() => object.scene.clone(), [object])

  useEffect(() => {
    if (checkQuality.quality === 'low-res') {
      object.scene.traverse((node) => {
        setShadow(node)
      })
    } else {
      desktopGlb.scene.traverse((node) => {
        setShadow(node)
      })
    }

    setTimeout(() => {
      loader.setFadeIn(false)
      onLoaderDone()
      loader.reportDone(props.name)
    }, [1500])
  }, [])

  useEffect(() => {
    setTimeout(() => (gl.shadowMap.autoUpdate = false), [2000])
  }, [])

  const setShadow = (mesh) => {
    if (mesh.type == 'Mesh') {
      mesh.castShadow = true
      mesh.receiveShadow = true
      // console.log(mesh.material.name)
      if (mesh.material?.name === 'Glass.001' || mesh.material?.name === 'Glass') {
        // console.log('found ', mesh)
        mesh.material.transparent = true
        mesh.material.alpha = 0.3
        mesh.material.opacity = 0.3
      }
    }
  }
  return (
    <>
      {checkQuality.quality === 'low-res' && (
        <group dispose={null}>
          <primitive object={object.scene} />
        </group>
      )}
      {checkQuality.quality === 'high-res' && (
        <group dispose={null}>
          <primitive object={desktopGlb.scene} />
        </group>
      )}

      <Lights roomName={'base'} />
      <Suspense fallback={null}>
        <MaterialChanger room={checkQuality.quality === 'low-res' ? object : desktopGlb} />
      </Suspense>
    </>
  )
}

Room.propTypes = {
  url: PropTypes.string,
}
