// export const products = {
//   base: ['A World of Concrete Ideas'],
//   Ready_Mix_Concrete: [
//     'Ready Mix Concrete',
//     'Enviro Tuff',
//     // {
//     //   name: 'Ready Mix Concrete',
//     // },
//     // {
//     //   name: 'Enviro Tuff',
//     // },
//   ],
// }

export const productList = [
  'Enviro Tuff',
  'Xtra Tuff',
  'Easy Tuff',
  'Thermo Tuff',
  'Mini Tuff',
  'Xtenso Tuff',
  'HI-Per Tuff',
  'Poro Tuff',
  'Rheo Tuff',
  'Flexi Tuff',
  'Quick Tuff',
  'Light Tuff',
  'Pave Tuff',
  'Impress Tuff',
  'Base Tuff',
  'Colour Tuff',
  'Denso Tuff',
  'Sparkle Tuff',
  'Tuff Blocks',
  'Tuff Easy Fix',
  'Tuff Duroplast',
  'Solid Recycled Concrete',
  'Hollow Recycled Concrete',
  'Flyash Recycled Concrete',
  'ZigZag',
  'Trihex',
  'Euphrates',
  'Colorado',
  'I Section',
  'Godrej Go TUFF App',
  'Concrete Testing Squad',
]

export const CityList = [
  'Mumbai',
  'Navi Mumbai',
  'MMR Region(Kalyan, Dombivili, Ambernath,Panvel, Khalapur, Khopoli)',
  'Pune(Pimpri Chinchwad)',
]
