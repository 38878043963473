import { useSpring } from '@react-spring/core'
import React, { useEffect, useState } from 'react'
import { useAppState, useMaterials } from '../../stores'
import { animated as a } from '@react-spring/web'
import { MaterialChangeScreens } from '../../../../constants'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import { css, StyleSheet } from 'aphrodite'
import QRCode from 'react-qr-code'

const QR = ({ value, style, onClick }) => {
  return (
    <div onClick={onClick} style={{ ...style }} className={css(styles.qr)}>
      <QRCode size={400} value={value} />
      <p style={{ marginTop: '1vh' }}>Scan me for AR</p>
    </div>
  )
}
export const ARButton = () => {
  const {
    currentContent: { name },
  } = useAppState()
  const { tuff_pavers } = useMaterials()
  const [urls, setUrls] = useState({ android: '', apple: '' })
  const { opacity } = useSpring({
    opacity: name === 'tuff_pavers' || name === 'tuff_pavers' ? 1 : 0,
  })
  const [showQR, setShow] = useState(false)
  const baseurl = window.location.origin
  useEffect(() => {
    let currName = MaterialChangeScreens.tuff_pavers[tuff_pavers].name
      .split(' ')
      .join('_')
      .toLowerCase()
    setUrls({
      android: baseurl + '/' + 'AR/' + currName + '.glb',
      apple: baseurl + '/' + 'AR/' + currName + '.usdz',
    })
    setShow(``)
  }, [tuff_pavers])
  useEffect(() => {
    if (name !== 'tuff_pavers') setShow('')
  }, [name])
  // const AndroidURL = window.location.origin + '/content/AR/'
  // const IOSIntent
  // //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.usdz";
  // const qr = useQR()
  // const AndroidIntent =
  //   'intent://arvr.google.com/scene-viewer/1.0?file=' +
  //   AndroidURL +
  //   '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'
  // const [QR, setQR] = useState(false)

  function OnRequestAR() {
    window.open(AndroidIntent)
  }
  function OnRequestARDesktop() {
    // console.log('setting')
    // console.log(`https://ar-redirect.autovrse.app//?android=${urls.android}&ios=${urls.apple}`)
    if (!showQR)
      setShow(`https://ar-redirect.autovrse.app//?android=${urls.android}&ios=${urls.apple}`)
    else setShow(``)
    // qr.setQR(`https://master.dy2v8xoym67fn.amplifyapp.com/?android=${AndroidURL}&ios=${IOSIntent}`)
  }

  return (
    <>
      {!isIOS && (
        <>
          {showQR && (
            <>
              <QR
                onClick={() => setShow('')}
                size={500}
                value={showQR}
                style={{ opacity, pointerEvents: name === 'tuff_pavers' ? 'auto' : 'none' }}
              />
            </>
          )}
          <a.svg
            style={{
              opacity,
              position: 'absolute',
              bottom: '2.5vh',
              right: '1vw',
              cursor: 'pointer',
              zIndex: 110,
              pointerEvents: name === 'tuff_pavers' ? 'auto' : 'none',
            }}
            height="8vh"
            viewBox="0 0 49 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => (isAndroid ? OnRequestAR() : isDesktop ? OnRequestARDesktop() : null)}
          >
            {!isIOS && <a target="_blank" href={isIOS ? 'www.google.com' : 'www'}></a>}
            <rect x="0.191895" y="1.53516" width="48" height="48" rx="24" fill="#F2F2F2" />
            <path
              d="M16.128 12.4688H11.1274V17.8043"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M37.2571 17.8043L37.2571 12.4688L32.2565 12.4687"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M32.2565 38.5996L37.2571 38.5996L37.2571 33.2641"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M11.1274 33.2641L11.1274 38.5996L16.128 38.5996"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M17.8668 24.641L17.4988 25.921H20.1708L19.8028 24.641C19.6322 24.0863 19.4668 23.5157 19.3068 22.929C19.1575 22.3423 19.0082 21.761 18.8588 21.185H18.7948C18.6455 21.7717 18.4962 22.3583 18.3468 22.945C18.1975 23.521 18.0375 24.0863 17.8668 24.641ZM14.3628 30.209L17.7708 19.745H19.9468L23.3548 30.209H21.4028L20.5868 27.377H17.0668L16.2508 30.209H14.3628ZM25.4447 30.209V19.745H28.9807C29.5247 19.745 30.0314 19.7983 30.5007 19.905C30.97 20.0117 31.3754 20.1877 31.7167 20.433C32.058 20.6677 32.3247 20.9823 32.5167 21.377C32.7194 21.7717 32.8207 22.2623 32.8207 22.849C32.8207 23.649 32.6287 24.2943 32.2447 24.785C31.8714 25.2757 31.37 25.6277 30.7407 25.841L33.2367 30.209H31.1567L28.9007 26.113H27.3007V30.209H25.4447ZM27.3007 24.641H28.7887C29.5034 24.641 30.0474 24.4917 30.4207 24.193C30.8047 23.8943 30.9967 23.4463 30.9967 22.849C30.9967 22.241 30.8047 21.8197 30.4207 21.585C30.0474 21.3503 29.5034 21.233 28.7887 21.233H27.3007V24.641Z"
              fill="#3D3D3D"
            />
          </a.svg>
        </>
      )}
      {isIOS && (
        <a target="_blank" href={urls.apple}>
          <a.svg
            style={{
              opacity,
              position: 'absolute',
              bottom: '2.5vh',
              right: '1vw',
              cursor: 'pointer',
              zIndex: 110,
              pointerEvents: name === 'tuff_pavers' ? 'auto' : 'none',
            }}
            height="8vh"
            viewBox="0 0 49 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // onClick={() => (isAndroid ? OnRequestAR() : isDesktop ? OnRequestARDesktop() : null)}
          >
            {!isIOS && <a target="_blank" href={isIOS ? 'www.google.com' : 'www'}></a>}
            <rect x="0.191895" y="1.53516" width="48" height="48" rx="24" fill="#F2F2F2" />
            <path
              d="M16.128 12.4688H11.1274V17.8043"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M37.2571 17.8043L37.2571 12.4688L32.2565 12.4687"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M32.2565 38.5996L37.2571 38.5996L37.2571 33.2641"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M11.1274 33.2641L11.1274 38.5996L16.128 38.5996"
              stroke="#3D3D3D"
              strokeWidth="1.75"
              strokeLinecap="square"
            />
            <path
              d="M17.8668 24.641L17.4988 25.921H20.1708L19.8028 24.641C19.6322 24.0863 19.4668 23.5157 19.3068 22.929C19.1575 22.3423 19.0082 21.761 18.8588 21.185H18.7948C18.6455 21.7717 18.4962 22.3583 18.3468 22.945C18.1975 23.521 18.0375 24.0863 17.8668 24.641ZM14.3628 30.209L17.7708 19.745H19.9468L23.3548 30.209H21.4028L20.5868 27.377H17.0668L16.2508 30.209H14.3628ZM25.4447 30.209V19.745H28.9807C29.5247 19.745 30.0314 19.7983 30.5007 19.905C30.97 20.0117 31.3754 20.1877 31.7167 20.433C32.058 20.6677 32.3247 20.9823 32.5167 21.377C32.7194 21.7717 32.8207 22.2623 32.8207 22.849C32.8207 23.649 32.6287 24.2943 32.2447 24.785C31.8714 25.2757 31.37 25.6277 30.7407 25.841L33.2367 30.209H31.1567L28.9007 26.113H27.3007V30.209H25.4447ZM27.3007 24.641H28.7887C29.5034 24.641 30.0474 24.4917 30.4207 24.193C30.8047 23.8943 30.9967 23.4463 30.9967 22.849C30.9967 22.241 30.8047 21.8197 30.4207 21.585C30.0474 21.3503 29.5034 21.233 28.7887 21.233H27.3007V24.641Z"
              fill="#3D3D3D"
            />
          </a.svg>
        </a>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  qr: {
    position: 'absolute',
    zIndex: 130,
    background: 'grey',
    right: '4.5vw',
    bottom: '7vh',
    width: 470,
    height: 500,
    textAlign: 'center',
    paddingTop: '3vh',
    borderRadius: '5px',
  },
})
