import React from 'react'

export const CloseIcon = (props) => {
  return (
    // <div {...props}>
    <svg
      {...props}
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5322 6.22168L6.21852 17.5354"
        stroke="#888888"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M17.5322 17.5352L6.21852 6.22145"
        stroke="#888888"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
    // </div>
  )
}
