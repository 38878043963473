import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment } from './environment/Environment'
// import { Designer } from './designer/Designer'
import { useAppState } from './stores'
import { RoutesManager } from './core/routes/RoutesManager'
// import { Leva } from 'leva'
// import { designerEnabled } from './settings'
import { AdaptiveDpr } from '@react-three/drei'
import PreloadModel from './environment/PreloadModel'
import CustomDPR from './core/CustomDPR'
import RenderStuff from './core/RenderStuff'
import QualitySwitch from './UI/QualitySwitch'
import Quality from './core/Quality'

export const App_3dbuilder = ({ onLoaderDone, setCanLoad, canLoad, everythingLoaded }) => {
  const useApp = useAppState()
  useEffect(() => {
    useApp.fetchRooms()
  }, [])

  return (
    useApp.allRooms && (
      <>
        {/* <CustomHistory /> */}
        <RoutesManager />
        <Canvas performance={[0.6, 1]} frameloop="demand" colorManagement>
          {!canLoad && (
            <Suspense fallback={null}>
              <PreloadModel
                setCanLoad={setCanLoad}
                loadModel={canLoad}
                everythingLoaded={everythingLoaded}
              />
              <Quality />
            </Suspense>
          )}
          {everythingLoaded && (
            <>
              <RenderStuff />
              {/* <QualitySwitch /> */}
            </>
          )}
          {canLoad && (
            <>
              <CustomDPR qualityMode={everythingLoaded} />
              <Environment onLoaderDone={onLoaderDone} />
            </>
          )}
          {/* {useApp.room?.name && <Lights />} */}
          {/* <Suspense fallback={null}>
            <PreloadModel />
          </Suspense> */}
          {/* <SwitchToLandscape /> */}
          {/* <AdaptiveDpr /> */}
          {/* <Stats /> */}
          {/* <Performance /> */}
          {/* {designerEnabled && <Perf />} */}
          {/* <MainScene /> */}
          {/* <Box /> */}
          {/* <Sky /> */}
        </Canvas>
        {/* <LoadingManager /> */}
        {/* <Designer /> */}
        {/* <UIManager /> */}
        {/* <Leva hidden={!designerEnabled} /> */}
      </>
    )
  )
}
