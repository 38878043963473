import { StyleSheet, css } from 'aphrodite'
import React from 'react'
import { animated as a } from '@react-spring/web'
import { colors } from '../../../../theme'
export const InfoHotspot = (props) => {
  return (
    <>
      <a.div {...props} className={css(styles.container)}>
        <div className={css(styles.bullet)}></div>
        <div className={css(styles.triangle)}></div>
        <p className={css(styles.p)}>{props.desc}</p>
      </a.div>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: '-0.5vw',
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: 'white',
    padding: '1.5vh 0.4vh 1.8vh 0vh',
    pointerEvents: 'none',
    width: '18vw',
  },
  p: {
    margin: 0,
    // width: '100%',
    fontSize: '1.8vh',
    lineHeight: '2.3vh',
    marginLeft: '2vw',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '0.8vh solid transparent',
    borderRight: ' 0.8vh solid transparent',
    position: 'absolute',
    left: '0.7vw',
    bottom: '-1.4vh',
    borderTop: '2vh solid rgba(0,0,0,0.8)',
  },
  bullet: {
    position: 'absolute',
    backgroundColor: colors.godrejGreen,
    width: '1.2vh',
    height: '1.2vh',
    top: '50%',
    borderRadius: '100%',
    left: '0.7vw',
    transform: 'translateY(-50%)',
  },
})
