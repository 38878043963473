import { useProgress, useDetectGPU } from '@react-three/drei'
import React, { useEffect, Suspense } from 'react'
import { Camera } from '../core/3d/Camera'
import { Skybox } from '../core/3d/Skybox'
import { useAppState, useLandscapeMode, useQualitySwitch } from '../stores'
import { HotspotManager } from './hotspots/HotspotManager'
import { Room } from './Room'
import { useLoadingManager } from '../stores'
import TreeInstancing from './trees/TreeInstancing'
import PreloadTexture from '../UI/assets/PreloadTexture'
import RockInstancing from './rocks/RockInstancing'
import WaterShader from './water/WaterShader'
import WaterIOS from './water/WaterIOS'
import { isIOS } from 'react-device-detect'

const Loading = () => {
  const { progress } = useProgress()
  const { setProgress } = useLoadingManager()
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => setProgress(progress), [progress])
  return null
}
export const MainScene = React.memo(({ onLoaderDone }) => {
  const { room, currentContent } = useAppState()
  const GPUTier = useDetectGPU()
  const { status } = useLandscapeMode()
  const quality = useQualitySwitch()

  // useEffect(() => {
  //   console.log('main scene: ' + GPUTier.tier)
  // })

  return (
    <>
      <Camera name={currentContent.name} />
      <Suspense fallback={<Loading />}>
        {!isIOS && (
          <>
            <RockInstancing />
            <TreeInstancing />
          </>
        )}

        <Room onLoaderDone={onLoaderDone} />

        {isIOS || quality.quality === 'low-res' ? <WaterIOS /> : <WaterShader />}
      </Suspense>
      <Suspense fallback={null}>
        <PreloadTexture />
      </Suspense>
      {room?.hotspots && status && <HotspotManager hotspots={room.hotspots} />}
      <Skybox />
    </>
  )
})
