import React, { useEffect, useState } from 'react'
import { useAppState, useCameraOverride, useHud } from '../../../stores'
import { StyleSheet, css } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import './ProductList.css'
import { SingleProductCard } from './SingleProductCard'

const Buttons = ({ res, setSelected, selected }) => {
  // const [condition, setCondition] = React.useState(null)
  const { setView } = useCameraOverride()
  const { setCurrentProduct } = useHud()
  const {
    allProductsInfo,
    setAllProductsInfo,
    currentContent: { name },
  } = useAppState()
  const handleClick = (k) => {
    setSelected(res[k])
    // console.log('check it', res[k].view)
    if (res[k].view != null) {
      setView(JSON.parse(res[k].view))
    }
  }

  useEffect(() => {
    if (selected?.suboptions) setAllProductsInfo(JSON.parse(selected.suboptions))
    else setAllProductsInfo([])
  }, [selected])
  useEffect(() => {
    name === 'products' && allProductsInfo && !selected.name && handleClick(4)
  }, [name, allProductsInfo])
  // console.log('res', selected.name)
  const seen = []
  const [hover, setHover] = useState(null)

  useEffect(() => {
    if (selected.name) {
      setCurrentProduct(
        selected.name
          .split('_')
          .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1, x.length))
          .join(' ')
          .split('')
          .filter((x) => x == ' ' || isNaN(x))
          .join('')
      )
    }
  }, [selected.name])

  function HoverEnter(item) {
    setHover(item)
  }
  function HoverExit() {
    setHover(null)
  }

  return (
    <ul className={css(styles.productlist)}>
      {res.map((data, k) => {
        if (seen.includes(data.title)) return
        seen.push(data.title)
        return (
          (data.type === 'options' || data.type === 'normal') && (
            <>
              <li
                key={k}
                onClick={() => handleClick(k)}
                onMouseEnter={() => {
                  HoverEnter(data.name)
                }}
                onMouseLeave={() => {
                  HoverExit()
                }}
                className={`button ${
                  selected.name === data.name || data.name === hover ? 'toggled' : ''
                }`}
                style={{
                  marginBottom: '2vh',
                  marginLeft: 0,
                  marginRight: 0,
                  borderRadius: 'none',
                  paddingLeft: '2vh',
                }}
              >
                {/* <div
                  style={{
                    borderLeft: ' 0.5vh solid #97BF55',
                    height: '4.5%',
                    position: 'sticky',
                    // top: 0,
                    left: 0,
                    marginTop: '-1vh',
                    display: selected.name === data.name ? 'block' : 'none',
                  }}
                ></div> */}
                {data.name === 'xtenso_tuff' && (
                  <div
                    style={{
                      margin: 0,
                      padding: '0.5vh',
                      // position: 'fixed',
                      marginTop: '-2vh',
                      marginBottom: '2vh',
                      marginLeft: '-0.5vh',
                      width: '13vh',
                    }}
                  >
                    <hr
                      className={css(styles.line)}
                      // style={{ border: '0.3vh solid rgba(255, 255, 255, 0.2)' }}
                    ></hr>
                  </div>
                )}
                {data.name
                  .split('_')
                  .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1, x.length))
                  .join(' ')
                  .split('')
                  .filter((x) => x == ' ' || isNaN(x))
                  .join('')}
              </li>
            </>
          )
        )
      })}
    </ul>
  )
}
export const ProductList = (props) => {
  const { currentContent, room } = useAppState()
  // only the hotspots with that are not sub categories will have desc
  // const condition = useMemo(() => currentContent.roomName && currentContent.desc, [currentContent])
  const { opacity } = useSpring({
    opacity: currentContent.name === 'products' ? 1 : 0, // 1->0
  })
  const [selected, setSelected] = useState({})

  useEffect(() => {
    currentContent.name !== 'products' && setSelected({})
  }, [currentContent.name])

  return (
    <a.div
      id="productList"
      style={{
        opacity,
        pointerEvents: currentContent.name === 'products' ? 'auto' : 'none',
      }}
      className={css(styles.container)}
    >
      <div className={css(styles.scroll)}>
        {room && <Buttons setSelected={setSelected} selected={selected} res={room?.hotspots} />}
      </div>
      {selected?.name && (
        <SingleProductCard
          unselect={() => setSelected({})}
          className={styles.card}
          currentContent={selected}
          noAnim
        />
      )}
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    color: '#F2F2F2',
    position: 'absolute',
    fontFamily: 'Work Sans',

    left: '0vh',
    top: '8.5vh',
    height: '100%',
    color: 'white',
    width: '11vw',
    textAlign: 'left',
    padding: '2.4vh 0.8vw 3.5vh 0.2vw',
    margin: '0vh 1vh 0vh 0vh',
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  line: {
    margin: 0,
    // transform: 'scale(2,0.2)',
    color: 'rgba(255, 255, 255, 0.2)',
    // height: '0.04vh',
    border: '0.05vh solid',
    // border: 0,
    // borderWidth: 0,
  },
  productlist: {
    paddingRight: '0rem',
    paddingLeft: '0rem',
    margin: '2vh 0vw 5vh 0vw',
    // background: 'red',
  },
  scroll: {
    // pointerEvents: 'auto',
    height: '90%',
    borderRadius: 4,
    // direction: 'rtl',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '0.3vw',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '2.4vh',
      height: '2.4vh',
    },
  },
  card: {
    left: '11.1vw',
    top: '0vh',
  },
})
