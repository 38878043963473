import { useFrame, useThree } from '@react-three/fiber'

function RenderStuff() {
  const { invalidate } = useThree()

  useFrame(() => {
    invalidate()
  })
  return null
}

export default RenderStuff
