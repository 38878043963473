import React from 'react'

function TuffLogo() {
  return (
    <div style={{ width: '26vh', height: '10vh' }}>
      <img src="/images/Tuff_logo.svg" style={{ width: '100%', height: '100%' }} />
    </div>
  )
}

export default TuffLogo
