import { useSpring } from '@react-spring/core'
import { StyleSheet, css } from 'aphrodite'
// import { useControls } from 'leva'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'wouter'
import { useAppState } from '../../../stores'
import { animated as a } from '@react-spring/web'
import { useMeasure } from 'react-use'
import { Pointer } from '../../assets/Pointer'

const tips = {
  real_estate: 'Click on a product to know more',
  // denso_tuff: 'See product here',
  tuff_pavers: 'Select a product to change paving',
  // tuff_blocks: 'Select a product to change blocks',
}
const scrollList = [
  { link: '/', message: 'Ready Mix Concrete' },
  {
    link: '/Ready_Mix_Concrete',
    message: 'Applications in Real Estate & Infrastructure',
  },
  { link: '/Ready_Mix_Concrete/real_estate', message: 'Applications in Embankments & Dams' },
  { link: '/Ready_Mix_Concrete/embankments', message: 'Applications in Roadworks & Bridges' },
  { link: '/Ready_Mix_Concrete/roadworks', message: 'All Products' },
  { link: '/Ready_Mix_Concrete/products', message: 'Walling And Paving Solutions' },
  {
    link: '/Walling_And_Paving_Solutions',
    message: 'View Products',
  },
  {
    link: '/Walling_And_Paving_Solutions/walling_and_paving',
    message: 'Walling & Paving Products',
  },
  {
    link: '/Walling_And_Paving_Solutions/walling_and_paving_products',
    message: 'Recycled Concrete',
  },
  {
    link: '/Walling_And_Paving_Solutions/recycled_concrete',
    message: 'Recycled Concrete Products',
  },
  {
    link: '/Walling_And_Paving_Solutions/recycled_concrete_product',
    message: 'Pre Fabricated Concrete',
  },
  { link: '/Pre_Fabricated_Concrete', message: 'Smart Services' },
  { link: '/Smart_Services', message: 'Sustainability' },
  { link: '/Sustainability', message: '' },
]
const animateScroll = () => {
  // api({
  //   from: { transform: 'translateY(0vh)' },
  //   transform: 'translateY(10vh)',
  // })
  // setTimeout(() => {
  //   api({ transform: 'translateY(-10vh)' })
  // }, [1000])
}
const Tip = ({ tip, ...props }) => {
  return (
    <a.div {...props} className={css(styles.text, styles.tipBox)}>
      <Pointer style={{ float: 'left', marginLeft: '0.4vw' }} />
      <span style={{ marginLeft: '0.5vw', marginRight: '0.4vw' }}>{tip}</span>
    </a.div>
  )
}
export const ScrollManager = () => {
  const {
    currentContent: { name },
  } = useAppState()
  const [location, setLocation] = useLocation()
  const [windowOpen, setWindow] = useState(true)
  const [api] = useSpring(() => {})

  const fadeIn = useSpring({
    from: { opacity: 0 },
    opacity: 1,
  })
  const threshold = 100

  const currIdx = useMemo(() => {
    return scrollList.findIndex(({ link }) => link === location)
  }, [location])

  //! handle scroll for chrome, safari, ff
  const handleScroll = (e) => {
    let delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail))
    if (
      // !enableScroll ||
      currIdx === -1 ||
      e?.path?.some(
        (ele) => ele.id === 'productCard' || ele.id === 'productList' || ele.id === 'disclaimer'
      )
    )
      return
    animateScroll(api)

    const idx = currIdx
    if ((delta || Math.abs(e.deltaY) > threshold) && windowOpen) {
      setWindow(false)
      setTimeout(() => setWindow(true), [1500])
      if (delta > 0) {
        idx !== 0 && setLocation(scrollList[idx - 1].link)
      } else {
        idx !== scrollList.length - 1 && setLocation(scrollList[idx + 1].link)
      }
    }
  }
  const [ref] = useMeasure()
  const arrowSpring = useSpring({
    // bottom: windowOpen ? '1%' : '-20%',
  })
  const { opacity } = useSpring({
    opacity: currIdx === -1 || currIdx === scrollList.length - 1 ? 0 : 1,
  })

  useEffect(() => {
    //! Touch devices
    let touchPos
    const idx = currIdx
    // store the touching position at the start of each touch
    document.body.ontouchstart = function (e) {
      touchPos = e.changedTouches[0].clientY
    }

    // detect wether the "old" touchPos is
    // greater or smaller than the newTouchPos
    document.body.ontouchmove = function (e) {
      let newTouchPos = e.changedTouches[0].clientY
      if (newTouchPos > touchPos) {
        // console.log('finger moving down')
        idx !== 0 && setLocation(scrollList[idx - 1].link)
      }
      if (newTouchPos < touchPos) {
        // console.log('finger moving up')
        idx !== scrollList.length - 1 && setLocation(scrollList[idx + 1].link)
      }
    }
    //! Chrome, firefox, safari detect mouse wheel
    document.body.addEventListener('DOMMouseScroll', handleScroll)
    document.body.addEventListener('mousewheel', handleScroll)

    return () => {
      document.body.removeEventListener('mousewheel', handleScroll)
      document.body.removeEventListener('DOMMouseScroll', handleScroll)
    }
  }, [location, windowOpen])
  // enableScroll

  return (
    <>
      {tips[name] && <Tip style={fadeIn} tip={tips[name]} />}
      <a.div style={{ opacity, ...arrowSpring }} className={css(styles.text)}>
        {currIdx != -1 && scrollList[currIdx].message
          ? scrollList[currIdx].message === 'none'
            ? ''
            : scrollList[currIdx].message
          : 'Scroll'}
      </a.div>
      <a.svg
        ref={ref}
        className={css(windowOpen ? styles.scroll : styles.scrollNoAnim)}
        style={{
          opacity,
          position: 'absolute',
          bottom: '1%',
          left: `50%`,
          zIndex: 50,
          // transform: 'translateX(-50%)',
          // background: 'red',
          cursor: 'pointer',
          pointerEvents: currIdx !== -1 ? 'auto' : 'none',
          ...arrowSpring,
        }}
        // width="62"
        onClick={() => handleScroll({ deltaY: 2 * threshold })}
        // height="76"
        height="11.4vh"
        viewBox="0 0 62 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3027 63.043L32.0098 63.7501L31.3027 64.4572L30.5956 63.7501L31.3027 63.043ZM44.0098 51.7501L32.0098 63.7501L30.5956 62.3359L42.5956 50.3359L44.0098 51.7501ZM30.5956 63.7501L18.5956 51.7501L20.0098 50.3359L32.0098 62.3359L30.5956 63.7501Z"
          fill="white"
        />
        <path
          d="M31.3027 51.043L32.0098 51.7501L31.3027 52.4572L30.5956 51.7501L31.3027 51.043ZM44.0098 39.7501L32.0098 51.7501L30.5956 50.3359L42.5956 38.3359L44.0098 39.7501ZM30.5956 51.7501L18.5956 39.7501L20.0098 38.3359L32.0098 50.3359L30.5956 51.7501Z"
          fill="white"
        />
      </a.svg>
    </>
  )
}
const translateKeyframes = {
  '0%': {
    transform: 'translate(-50%,0vh)',
  },

  '50%': {
    transform: 'translate(-50%,-0.9vh)',
  },

  '100%': {
    transform: 'translate(-50%,0vh)',
  },
}
const styles = StyleSheet.create({
  scroll: {
    animationName: translateKeyframes,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  scrollNoAnim: {
    transform: 'translate(-50%,0px)',
  },
  tipBox: {
    position: 'absolute',
    bottom: '20vh',
    backgroundColor: 'black',
    height: '3.5vh',
    lineHeight: '3.5vh',
    width: 'auto',
    whiteSpace: 'nowrap',
    // width: '10vw',
    // padding: '0.5%',
    // boxSizing: 'border-box',
  },
  text: {
    position: 'absolute',
    maxWidth: '57.5vw',
    bottom: '10vh',
    textAlign: 'center',
    left: '50% ',
    padding: 0,
    margin: 0,
    transform: 'translateX(-50%)',
    color: 'white',
    fontSize: '1.6vh',
    fontFamily: 'Work Sans',
  },
  textBottom: {
    bottom: '3vh',
  },
})
