import { useSpring } from '@react-spring/core'
import React, { useEffect, useState } from 'react'
import { cameraOverrides } from '../../../../constants'
import { useAppState, useCameraOverride } from '../../stores'
import { animated as a } from '@react-spring/web'

export const CameraOverrideButton = () => {
  const camera = useCameraOverride()
  const [active, setActive] = useState(false)
  const {
    currentContent: { name },
  } = useAppState()
  const { opacity } = useSpring({
    opacity: cameraOverrides[name] ? 1 : 0,
  })
  const style = {
    position: 'absolute',
    bottom: '11.5vh',
    right: '1vw',
    opacity: opacity,
    pointerEvents: cameraOverrides[name] ? 'auto' : 'none',
    cursor: 'pointer',
  }
  useEffect(() => {
    setActive(false)
  }, [name])
  useEffect(() => {
    if (!active) camera.setView({})
    else {
      camera.setView(cameraOverrides[name])
    }
  }, [active])
  return !active ? (
    <a.svg
      onClick={() => setActive((state) => !state)}
      style={style}
      height="8vh"
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.519531" y="0.871094" width="48" height="48" rx="24" fill="#F2F2F2" />
      <path
        d="M25.5433 21.9021H23.2317V19.5905V19.5908C23.2317 19.1264 22.8553 18.75 22.3913 18.75C21.9269 18.75 21.5505 19.1264 21.5505 19.5908V21.9023H19.2389L19.2392 21.9021C18.7748 21.9021 18.3984 22.2785 18.3984 22.7428C18.3984 23.2069 18.7749 23.5833 19.2392 23.5833H21.5508V25.8949H21.5505C21.5505 26.3589 21.9269 26.7353 22.3913 26.7353C22.8553 26.7353 23.2317 26.3589 23.2317 25.8949V23.5833H25.5433C26.0074 23.5833 26.3838 23.2069 26.3838 22.7428C26.3838 22.2785 26.0074 21.9021 25.5433 21.9021Z"
        fill="#3D3D3D"
      />
      <path
        d="M37.3359 36.4979L30.6832 29.845C33.1578 26.9565 33.9522 22.9886 32.7804 19.3697C31.6084 15.7509 28.6389 13.0019 24.9408 12.1119C21.2427 11.2222 17.3474 12.3198 14.6577 15.0092C11.9682 17.6989 10.8706 21.5938 11.7604 25.2924C12.6504 28.9905 15.3994 31.96 19.0181 33.1319C22.6369 34.3037 26.6048 33.5092 29.4934 31.0347L36.1464 37.6874C36.475 38.016 37.0074 38.016 37.3357 37.6874C37.6644 37.3591 37.6644 36.8267 37.3357 36.498L37.3359 36.4979ZM13.1447 22.7422C13.1447 20.2899 14.1188 17.9382 15.8529 16.2042C17.5869 14.4702 19.9386 13.496 22.3909 13.496C24.8429 13.496 27.1946 14.4701 28.9286 16.2042C30.6626 17.9382 31.6368 20.2899 31.6368 22.7422C31.6368 25.1942 30.6627 27.5459 28.9286 29.2799C27.1946 31.0139 24.8429 31.9881 22.3909 31.9881C19.9386 31.9881 17.5869 31.014 15.8529 29.2799C14.1189 27.5459 13.1447 25.1942 13.1447 22.7422Z"
        fill="#3D3D3D"
      />
    </a.svg>
  ) : (
    <a.svg
      onClick={() => setActive((state) => !state)}
      style={style}
      height="8vh"
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.384766" y="0.871094" width="48" height="48" rx="24" fill="#F2F2F2" />
      <path
        d="M23.097 21.9023H25.4085C25.8726 21.9023 26.249 22.2788 26.249 22.7431C26.249 23.2072 25.8726 23.5836 25.4085 23.5836H23.097H21.416H19.1044C18.6401 23.5836 18.2637 23.2072 18.2637 22.7431C18.2637 22.2788 18.6398 21.9026 19.1041 21.9026H21.4157L23.097 21.9023Z"
        fill="#3D3D3D"
      />
      <path
        d="M37.2011 36.4979L30.5484 29.845C33.023 26.9565 33.8175 22.9886 32.6457 19.3697C31.4736 15.7509 28.5042 13.0019 24.8061 12.1119C21.1079 11.2222 17.2126 12.3198 14.5229 15.0092C11.8334 17.6989 10.7359 21.5938 11.6256 25.2924C12.5156 28.9905 15.2646 31.96 18.8834 33.1319C22.5021 34.3037 26.47 33.5092 29.3587 31.0347L36.0116 37.6874C36.3402 38.016 36.8726 38.016 37.201 37.6874C37.5296 37.3591 37.5296 36.8267 37.201 36.498L37.2011 36.4979ZM13.0099 22.7422C13.0099 20.2899 13.984 17.9382 15.7181 16.2042C17.4521 14.4702 19.8038 13.496 22.2561 13.496C24.7081 13.496 27.0599 14.4701 28.7939 16.2042C30.5279 17.9382 31.5021 20.2899 31.5021 22.7422C31.5021 25.1942 30.5279 27.5459 28.7939 29.2799C27.0599 31.0139 24.7081 31.9881 22.2561 31.9881C19.8038 31.9881 17.4521 31.014 15.7181 29.2799C13.9841 27.5459 13.0099 25.1942 13.0099 22.7422Z"
        fill="#3D3D3D"
      />
    </a.svg>
  )
}
