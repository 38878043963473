import React from 'react'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { useAppState } from '../../stores'
const names = ['tuff_pavers', 'tuff_blocks']
export const Seal = (props) => {
  const {
    currentContent: { name },
  } = useAppState()

  const { opacity } = useSpring({
    opacity: names.includes(name) ? 1 : 0,
  })
  return (
    <a.img
      style={{ opacity, pointerEvents: 'none', top: '14.5vh' }}
      {...props}
      height="100%"
      src="/images/seal.png"
    />
  )
}
