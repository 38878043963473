import { useTexture } from '@react-three/drei'

function PreloadTexture() {
  const tex = [
    '/textures/zigzag/normal.webp',
    '/textures/trihex/normal.webp',
    '/textures/euphrates/normal.webp',
    '/textures/colorado/normal.webp',
    '/textures/isection/normal.webp',
  ]
  const texture = useTexture(tex)

  return null
}

export default PreloadTexture
