import React from 'react'

function RightArrow() {
  return (
    <svg
      width="2vh"
      height="auto"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.65723L14 8.65723M14 8.65723L10 12.6572M14 8.65723L2 8.65723"
        stroke="#3D3D3D"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default RightArrow
