export const logEnabled = false

export const shadowResolution = 14 // 2^shadowRes

export const gaConfig = {
  enabled: true,
  code: 'G-KMR3T0JGSM',
}

export const prefabContent = {
  bench1: {
    name: 'Pre Fabricated Concrete',
    subTitle: 'Strength & Sustainability',
    desc: 'Our Design team constantly strives to offer innovative and functional products that are good for the community and green for the environment.',
  },
  boxCulvert: {
    name: 'Box Culvert',
    subTitle: 'Strength & Sustainability',
    desc: 'Precast Concrete products such as Box Culverts manufactured using Recycled Concrete Materials',
  },
}

export const MaterialChangeScreens = {
  tuff_pavers: [
    {
      name: 'ZigZag',
      src: '/images/thumbnails/ZIGZAG.png',
      texture: '/textures/zigzag/',
      keyVal: 'Example 1: This is an expale\nExample 2: This is an expale',
      desc: 'This is the desc \n\nthickness: 38\n Lorem ipsem',
      color: '#CE7442',
    },
    {
      name: 'Trihex',
      texture: '/textures/trihex/',
      src: '/images/thumbnails/TRIHEX.png',
      keyVal: 'Example 1: This is an expale\nExample 3: This is an expale',
      desc: 'This is the desc \n\nthickness: 38\n Lorem ipsem',
      color: '#C59A47',
    },
    {
      name: 'Euphrates',
      texture: '/textures/euphrates/',
      src: '/images/thumbnails/EUPHRATES.png',
      keyVal: 'Example 1: This is an expale\nExample 4: This is an expale',
      desc: 'This is the desc \n\nthickness: 38\n Lorem ipsem',
      color: '#5B7CBF',
    },
    {
      name: 'Colorado',
      texture: '/textures/colorado/',
      src: '/images/thumbnails/COLORADO.png',
      keyVal: 'Example 1: This is an expale\nExample 5: This is an expale',
      desc: 'This is the desc \n\nthickness: 38\n Lorem ipsem',
      color: '#77BA67',
    },
    {
      name: 'I Section',
      texture: '/textures/isection/',
      src: '/images/thumbnails/SECTION.png',
      keyVal: 'Example 1: This is an expale\nExample 6: This is an expale',
      desc: 'This is the desc \n\nthickness: 38\n Lorem ipsem',
      color: '#C66C6C',
    },
  ],
  tuff_blocks: [
    {
      name: 'Solid Recycled Concrete',
      src: '/images/thumbnails/SOLID.png',
      view: '{"position":{"x":-29.990158862868185,"y":2.6024143410932896,"z":-59.22338515983057},"lookAt":{"x":-15.43133320354226,"y":2.9665577054733605,"z":-70.15730431321843},"fov":30}',
    },
    {
      name: 'Hollow Recycled Concrete',
      src: '/images/thumbnails/HOLLOW.png',
      view: '{"position":{"x":-26.940032620297764,"y":7.7025311087762605,"z":-66.35664521552059},"lookAt":{"x":-8.911791119498607,"y":7.169956222466376,"z":-74.93569261460517},"fov":30}',
    },
    {
      name: 'Flyash Recycled Concrete',
      src: '/images/thumbnails/FLYASH.png',
      view: '{"position":{"x":-17.13743934538771,"y":8.70834746667445,"z":-151.01423158409148},"lookAt":{"x":-1.715008372268665,"y":8.285744295010447,"z":-123.33361086934856},"fov":30}',
    },
  ],
  tuffblocks_aac: [
    {
      name: 'Autoclaved Aerated Concrete',
      src: '/images/thumbnails/SOLID.png',
    },
  ],
}

export const MaterialChangeText = {
  tuff_blocks: [
    {
      heading: 'Blocks available in:',
      desc: `
    4” (390x100x190mm)\n
    5” (390x125x190mm)\n
    6” (390x150x190mm)\n
    8” (390x190x190mm)
    `,
      keyVal: `Dimensional Accuracy: +/-2mm\n
    Compressive Strength: 6 MPa+\n
    Water Absorption: <10%\n
    Density: 1800-2400 kg/cum\n
    Weight: 14-32 kgs`,
    },
    {
      heading: 'Blocks available in:',
      desc: `
    4” (390x100x190mm)\n
    5” (390x125x190mm)\n
    6” (390x150x190mm)\n
    8” (390x190x190mm)
    `,
      keyVal: `Dimensional Accuracy: +/-2mm\n
    Compressive Strength: 3.5 MPa+\n
    Water Absorption: <10%\n
    Density: 1400-2000 kg/cum\n
    Weight: 12-26  kgs`,
    },
    {
      heading: 'Blocks available in:',
      desc: `230 X 80X 120mm
    `,
      keyVal: `Dimensional Accuracy: +/-2mm\n
    Compressive Strength: 6 MPa+\n
    Water Absorption: <10%\n
    Density: 1800-2400 kg/cum\n
    Weight: 14-32 kgs`,
    },
  ],
  tuff_pavers: {
    heading: 'All pavers are available in: ',
    keyVal: `Thickness: 80mm Dimensional \n
    Accuracy: +/-2 mm \n
    Compressive Strength: 35-50 MPa. \n
    Custom colour options available :`,
    desc: '',
  },
  tuffblocks_aac: {
    title: 'Autoclaved Aerated Concrete',
    src: '/images/thumbnails/SOLID.png',
    heading: 'Blocks available in:',
    desc: `4” (390x100x190mm)\n
    5” (390x125x190mm)\n
    6” (390x150x190mm)\n
    8” (390x190x190mm)
    `,
    keyVal: `Dimensional Accuracy: +/-2mm\n
    Compressive Strength: 6 MPa+\n
    Water Absorption: <10%\n
    Density: 1800-2400 kg/cum\n
    Weight: 14-32 kgs`,
  },
}

export const cameraOverrides = {
  tuff_pavers: {
    position: { x: -4.4029096853656, y: 14.065078582869502, z: -49.55640607420625 },
    lookAt: { x: 13.881811673997543, y: -1.1178096322709972, z: -46.05575301479873 },
    fov: 30,
  },
  tuff_blocks: {
    position: { x: 6, y: 2, z: 3 },
    lookAt: { x: 1, y: 2, z: 3 },
  },
}

export const cameraStart = {
  position: { x: 124.68033786103635, y: 41.759659904124604, z: 203.98367592134883 },
  lookAt: { x: 105.76697345224446, y: 13.933360526703112, z: -13.708000616169436 },
  fov: 30,
}
