import { useEffect, useMemo, useRef, useState } from 'react'
import { useMaterials } from '../stores'
import { MaterialChangeScreens } from '../../../constants'
import { useTexture } from '@react-three/drei'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { Color, Vector2 } from 'three'
import * as THREE from 'three'

export const MaterialChanger = ({ room }) => {
  const { tuff_pavers } = useMaterials()
  const isFirstRender = useRef(true)
  const [animation, setAnim] = useState('fadein')
  const [intTexture, setTexture] = useState(MaterialChangeScreens.tuff_pavers[tuff_pavers].texture)
  // const base = useTexture(intTexture + 'base.webp')
  const normal = useTexture(intTexture + 'normal.webp')

  const [paverColor, setPaverColor] = useState(MaterialChangeScreens.tuff_pavers[tuff_pavers].color)

  useEffect(() => {
    normal.wrapS = THREE.RepeatWrapping
    normal.wrapT = THREE.RepeatWrapping
    normal.repeat = new Vector2(2, 2)
  }, [normal])

  const material = useMemo(() => {
    let met
    room.scene.traverse((x) => {
      if (x.material?.name === 'Texs_9_Paver_area') {
        met = x.material
      }
    })
    return met
  }, [])
  useEffect(() => {
    if (isFirstRender.current) {
      // normal.wrapS = isFirstRender.current.wrapS
      isFirstRender.current = false
      return
    }
    setAnim('fadeout')
  }, [tuff_pavers])

  useEffect(() => {
    material.normalMap = normal
    // console.log(material)
  }, [intTexture])

  useEffect(() => {
    let time = 2
    if (animation === 'fadein') {
      const tween = new TWEEN.Tween({ x: 0 }).to({ x: 1 }, time).onUpdate((state) => {
        // console.log('paver color: ' + paverColor)
        material.color.lerpColors(material.color, new Color(paverColor), state.x)
      })
      // .onUpdate((state) => (material.color = state))
      tween.start()
    } else if (animation === 'fadeout') {
      const tween = new TWEEN.Tween({ x: 1 })
        .to({ x: 0 }, time)
        .onUpdate((state) => {
          // material.color = `rgb(${state.x},${state.x},${state.x})`
          material.color.lerpColors(material.color, new Color(state.x, state.x, state.x), state.x)
          // material.color.set(paverColor)
          // material.color = state
          // console.log('material array: ' + color)
        })
        .onComplete(() => {
          setTexture(MaterialChangeScreens.tuff_pavers[tuff_pavers].texture)
          setPaverColor(MaterialChangeScreens.tuff_pavers[tuff_pavers].color)
          setAnim('fadein')
        })
      tween.start()
    }
  }, [animation])
  return null
}
