import { useEffect } from 'react'
import { useLocation } from 'wouter'
import { useAppState } from '../../stores'
// import
const parseLoc = (loc) => {
  loc = loc.split('/').filter((loc) => loc !== '')
  // loc.length === 0 && loc.push('/')
  return loc
}
const findRoom = (loc, rooms) => {
  if (!loc) return rooms.find((room) => room.name === 'base')
  return rooms.find((room) => room.name === loc)
  // locs.find((loc) => {
  //   room = rooms.find((room) => room.name === loc)

  //   if (room) {
  //     return true
  //   }
  // })
  // return room ? room : rooms.find((room) => room.name === 'base')
}
// const findHotspot = (locs, currRoom) => {}
export const RoutesManager = () => {
  const [location] = useLocation()
  const { allRooms, room, setAll, currentContent } = useAppState()
  // const loader = useLoadingManager()

  useEffect(() => {
    if (allRooms.length) {
      let parsedLoc = parseLoc(location)
      let currIsRoom = parsedLoc.length < 2
      let currRoom = findRoom(parsedLoc[0], allRooms)
      // let currRoom = findRoom([...parsedLoc], allRooms)
      let currHp = currRoom.hotspots.find((hp) => hp.name === parsedLoc[parsedLoc.length - 1])
      // let isLoaded = loader.itemsDone.includes(currRoom.name)
      // if (!room) {
      //   setAll({ currentContent: currRoom, room: currRoom })
      // }
      // currRoom !== room && loader.setFadeIn(true)
      setTimeout(() => {
        if (currIsRoom || !room) {
          if (currHp) setAll({ currentContent: currHp, room: currRoom })
          else setAll({ currentContent: currRoom, room: currRoom })
        } else {
          setAll({ currentContent: currHp || currRoom, room: currRoom })
        }
      }, [currRoom !== room && room ? 0 : 0])
      // if (currHp) {
      //   setAll({ currentContent: currHp })
      // }
    }
  }, [location, allRooms])
  useEffect(() => {}, [currentContent])
  return null
  // <div>
  //   <button onClick={() => setLocation('/something/sx')}>push history</button>
  //   <button onClick={() => setLocation('/sx')}>push history</button>
  //   <p>{location}</p>
  // </div>
}
