import React from 'react'

export const More = ({ height }) => {
  return (
    <svg height={height} viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.26709" y="8.39648" width="20" height="20" rx="10" fill="#3D3D3D" />
      <path d="M18.2671 11.3965V25.3965" stroke="#F2F2F2" strokeWidth="2" />
      <path d="M25.2671 18.3965L11.2671 18.3965" stroke="#F2F2F2" strokeWidth="2" />
    </svg>
  )
}
