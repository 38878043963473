import { useFrame } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { useAppState, useCameraOverride, useCustomCamera, useLoadingManager } from '../../stores'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { cameraStart } from '../../../../constants'

const clamp = (x) => (x > 6000 ? 6000 : x < 2000 ? 2000 : x)
const Tween = ({ object, to, time = 2000, instant = false }) => {
  const { setisTweening } = useCustomCamera()
  useEffect(() => {}, [object.target])
  useEffect(() => {
    // if (instant) {
    //   object.set(...Object.values(to))
    //   setisTweening(false)
    // } else {
    // console.log('time inside', time)
    const tween = new TWEEN.Tween(object)
      .to(to, !instant ? time : 1000)
      .onComplete(() => setisTweening(false))
      .easing(TWEEN.Easing.Quadratic.InOut)
    tween.start()
    // }
  }, [to, time])
  useFrame(() => {
    TWEEN.update()
  })
  return null
}
export const TweenManager = React.memo(() => {
  const { currentContent, allRooms } = useAppState()
  const { camera, controls, setisTweening } = useCustomCamera()
  const { fadeIn } = useLoadingManager()
  const firstTween = useRef(true)
  const [localTo, setLocalTo] = useState({
    position: { ...cameraStart.position },
    lookAt: { ...cameraStart.lookAt },
    time: 5000,
  })
  const [time, setTime] = useState(100)
  const cameraOverride = useCameraOverride()
  const loader = useLoadingManager()
  useEffect(() => {
    if (
      currentContent.name === 'tuff_pavers' ||
      currentContent.name === 'tuff_blocks' ||
      currentContent.name === 'products'
    ) {
      if (!cameraOverride.view.position) {
        let currentView = JSON.parse(currentContent.view)
        setTime(2000) // remove this for lafs like movement ig
        setLocalTo({
          position: currentView.position,
          lookAt: currentView.lookAt,
          fov: 50,
          time: 2000,
        })
      } else {
        setLocalTo({
          position: cameraOverride.view.position,
          lookAt: cameraOverride.view.lookAt,
          fov: 50,
          time: 2000,
        })
      }
    }
  }, [cameraOverride.view])
  useEffect(() => {
    const multiplier = 24
    // if(fadeIn) return // dont do anything if loading
    // else if(!fadeIn && firstTween.current){
    //   firstTween.current = false

    // }
    // if (currentContent.name === 'Smart_Services' || currentContent.name === 'Sustainability') return
    // console.log('inside currContent', fadeIn)
    if (currentContent && (!fadeIn || currentContent.name !== 'base')) {
      setisTweening(true)

      if (currentContent.view) {
        let currentView = JSON.parse(currentContent.view)
        let time
        if (firstTween.current) {
          time = 4000
          firstTween.current = false
        } else {
          time = 2000 // remove this for lafs like movement ig
        }
        setLocalTo({
          position: currentView.position,
          lookAt: currentView.lookAt,
          fov: 50,
          time: time,
        })
      }
    } else if (fadeIn && currentContent.name === 'base') {
      setTime(100)
      setLocalTo({
        position: cameraStart.position,
        lookAt: cameraStart.lookAt,
        fov: 50,
        time: 1000,
      })
    }
  }, [currentContent, fadeIn]) //add seperate for hotspot this way it makes sense only animate when hotspots change
  useEffect(() => {
    if (!fadeIn) {
    }
  }, [fadeIn])
  // useEffect(() => {

  //   setisTweening(true)
  //   let hotspots = allRooms.find((room) => room.name === location)?.hotspots
  //   let currentHotspotView = hotspots.find((currHotspot) => currHotspot.id === hotspot)?.view
  //   if (currentHotspotView) {
  //     currentHotspotView = JSON.parse(currentHotspotView)
  //     // const currentHotspotView = currentRoomView
  //     setTime(2000) // remove this for lafs like movement ig
  //     setLocalTo({
  //       position: currentHotspotView.position,
  //       lookAt: currentHotspotView.lookAt,
  //       fov: 50,
  //     })
  //   }

  // }, [hotspot])
  useFrame(() => {
    camera.updateProjectionMatrix()
    controls.update()
  })
  return (
    <>
      <Tween
        time={localTo.time}
        instant={loader.fadeIn}
        object={camera.position}
        to={localTo.position}
      />
      <Tween
        time={localTo.time}
        instant={loader.fadeIn}
        object={controls.target}
        to={localTo.lookAt}
      />
    </>
  )
})
