import './App.css'
import { App_3dbuilder } from './framework/3d-builder/App_3dbuilder'
import { gaConfig } from './constants'
import { activateGA } from './helper/google-analytics'
import { UIManager } from './framework/3d-builder/UI/UIManager'
import { LoadingManager } from './framework/3d-builder/core/3d/LoadingManager'
import LandscapeDependent from './framework/3d-builder/UI/LandscapeDependent'
import SwitchToLandscape from './framework/3d-builder/UI/fullscreen/components/SwitchToLandscape'
import { Canvas } from '@react-three/fiber'
import PreloadModel from './framework/3d-builder/environment/PreloadModel'
import { Suspense, useEffect, useState } from 'react'
import CustomDPR from './framework/3d-builder/core/CustomDPR'
import { isIOS } from 'react-device-detect'
import QualitySwitch from './framework/3d-builder/UI/QualitySwitch'
import Quality from './framework/3d-builder/core/Quality'

activateGA(gaConfig.code)
// Styleguide - https://github.com/airbnb/javascript - MUST READ
// Vault v1.0
// - Added eslint enforcing
// - Added prettier enforcing
// - Added Panorama Viewer, change the App.js to ./framework/panorama-viewer/App.js

// Contact Admin to enable CI/CD on your branch

// Use Google Chrome & Firefox simultaneously for any testing

function App() {
  const [canLoad, setCanLoad] = useState(false)
  const [everythingLoaded, setEverythingLoaded] = useState(false)

  function EverythingLoaded() {
    setEverythingLoaded(true)
  }

  // useEffect(() => {
  //   console.log('everything loaded' + everythingLoaded)
  // })

  return (
    <div
      style={{
        position: 'absolute',
        top: `${isIOS ? '3vh' : 0}`,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {/* {canLoad && ( */}
      <>
        {everythingLoaded && <QualitySwitch />}

        <SwitchToLandscape />
        <App_3dbuilder
          onLoaderDone={EverythingLoaded}
          setCanLoad={setCanLoad}
          canLoad={canLoad}
          everythingLoaded={everythingLoaded}
        />
        <LandscapeDependent>
          <UIManager />
        </LandscapeDependent>
        <LoadingManager />
      </>
      {/* )} */}
    </div>
  )
}

export default App
