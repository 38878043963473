import { Html } from '@react-three/drei'
import React, { useMemo, useRef } from 'react'
import { useAppState, useCustomCamera } from '../../stores'
import { useLocation } from 'wouter'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { designerEnabled, getFileUrl } from '../../settings'
import { css, StyleSheet } from 'aphrodite'
import { pulse } from 'react-animations'
import { HotspotHover } from './HotspotHover'
import { More } from '../../UI/assets/More'
import { InfoHotspot } from './InfoHotspot'
import PropTypes from 'prop-types'

const Hotspot = (props) => {
  const { currentContent, allProductsInfo } = useAppState()

  const shouldShow = useMemo(() => {
    if (
      currentContent.name === 'recycled_concrete' ||
      currentContent.name === 'walling_and_paving'
    ) {
      return false
    }
    // if (
    //   currentContent.name === 'walling_and_paving_products' ||
    //   currentContent.name === 'recycled_concrete_product'
    // ) {
    //   return true
    // }
    let subs = JSON.parse(currentContent.suboptions)

    if (currentContent.name === props.name) return false
    if (props.helper) return true
    if (props.type === 'info' && subs.includes(props.name)) return true
    if (currentContent.name === 'products') {
      // we are at allproducts page
      if (props.type === 'info' && allProductsInfo.includes(props.name)) {
        return true
      } else {
        return false
      }
    } else if (!currentContent.roomName) {
      // current is a room
      if (currentContent.name === 'Pre_Fabricated_Concrete') return subs.includes(props.name)
      return false
    } else if (currentContent.type === 'subcat') {
      // if curr is subcat and props.subcat then show, otherwise only show if included in suboptions
      if (props.type === 'subcat') return true
      return subs.includes(props.name)
    } else if (currentContent.type === 'normal') {
      return subs.includes(props.name) && props.type === 'info'
    } else {
      return false
    }
  }, [currentContent.roomName, currentContent.name, props.name, props.helper, allProductsInfo])
  const { opacity } = useSpring({
    opacity: shouldShow ? 1 : 0,
    delay: shouldShow && !props.type === 'info' ? 1500 : 0,
    config: config.slow,
  })
  // const { transform } = useSpring({
  //   from: { transform: 'scale(1)' },
  //   transform: 'scale(1.4)',
  //   loop: true,
  //   // reverse: ,
  // })

  // const handleClick = () => {

  //   // customHistory.setParams({ hotspot: props.id })
  // }
  const style = {
    opacity,
    // transform,
    cursor: 'pointer',
    pointerEvents: shouldShow ? 'auto' : 'none',
  }
  return (
    <group position={Object.values(props.position)}>
      <Html
        zIndexRange={[1, 2]}
        zIndex={currentContent.suboptions.includes(props.name) ? 'auto' : 'none' ? 1 : 0}
        style={{
          position: 'absolute',
          zIndex: 1,
          transform: 'scale(0.8) translate(0%,-50%)',
          pointerEvents: shouldShow && props.type != 'info' ? 'auto' : 'none',
        }}
      >
        {/* <div // to see if hps are acutally getting centered
          style={{
            position: 'absolute',
            borderRadius: '100%',
            width: '3vh',
            height: '3vh',
            backgroundColor: 'black',
          }}
        ></div> */}
        {props.hotspotImage?.url ? (
          <a.div
            onPointerDown={props.handleClick}
            style={{ opacity }}
            className={css(styles.imageContainer)}
          >
            <More height="6vh" />
            <a.img
              style={{
                height: '10vh',
                width: 'auto',
                marginTop: '-2vh',
              }}
              src={getFileUrl(props.hotspotImage.url)}
            />
          </a.div>
        ) : props.type == 'info' ? (
          <InfoHotspot
            onPointerDown={props.handleClick}
            className={css(styles.hotspot)}
            style={style}
            desc={props.desc}
          />
        ) : (
          <HotspotHover
            title={props.title}
            onPointerDown={props.handleClick}
            className={css(styles.hotspot)}
            style={style}
          />
        )}
      </Html>
    </group>
  )
}
export const HotspotManager = (props) => {
  const { hotspotPosition } = useCustomCamera()
  const ref = useRef()
  const { currentContent } = useAppState()
  const [location, setLocation] = useLocation()
  // useEffect(() => {}, [hotspotPosition])
  // useFrame(() => {
  //   // ref.current?.update()
  // })
  return (
    <>
      {props.hotspots.map((hotspot, i) => {
        return (
          hotspot.position &&
          currentContent && (
            <Hotspot
              {...hotspot}
              handleClick={() => {
                if (hotspot.type === 'subcat') {
                  let split = location.split('/').filter((x) => x !== '')
                  setLocation('/' + split.slice(0, split.length - 1).join('/') + '/' + hotspot.name)
                } else {
                  setLocation(location + '/' + hotspot.name)
                }
              }}
              // name={hotspot.name}
              type={hotspot.type}
              position={JSON.parse(hotspot.position)}
              key={i}
            />
          )
        )
      })}
      {hotspotPosition && designerEnabled && (
        <Hotspot helper ref={ref} position={{ ...hotspotPosition }} type={'2'} />
      )}
    </>
  )
}

Hotspot.propTypes = {
  position: PropTypes.object,
  id: PropTypes.string,
}
HotspotManager.propTypes = {
  hotspots: PropTypes.array,
}

const styles = StyleSheet.create({
  hotspot: {
    height: '100%',
    ':hover': {
      transform: 'scale(1.2)',
    },
    animationName: pulse,
    animationIterationCount: 'infinite',
    animationDuration: '1s',
  },
  tuff: {
    animationName: pulse,
    animationIterationCount: 'infinite',
    animationDuration: '1s',
  },
  imageContainer: {
    ':hover': {
      transform: 'translate(-50%,-50%) scale(1.1)',
    },
    display: 'flex',
    height: '6vh',
    cursor: 'pointer',
    backgroundColor: 'white',
    paddingRight: '1vw',
    // border: '5px solid white',
    boxShadow: '0 0 0 0.9vh white',
    borderRadius: '4vh',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
  },
})
