import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useAppState, useCameraOverride, useHud, useMaterials } from '../../../stores'

export const MaterialChangeUi = ({ options }) => {
  const { setView } = useCameraOverride()
  const { setCurrentProduct } = useHud()

  const Button = () => {
    const materialIdx = useMaterials()
    const {
      currentContent: { name },
    } = useAppState()

    const setMaterial = (k) => {
      if (name === 'tuff_blocks') {
        materialIdx.setTuffBlocks(k)
      } else {
        materialIdx.setTuffPavers(k)
      }
    }

    const setSelected = (data) => {
      setCurrentProduct(data.name)
    }

    return options.map((data, k) => (
      <>
        {k === 0 && <div style={{ marginTop: '8vh' }}></div>}
        {/* {console.log('block: ' + data.name)} */}
        <div
          key={k}
          style={{
            width: '100%',
            backgroundColor:
              materialIdx[name] === k ? 'rgba(255,255,255,0.17)' : 'rgba(255,255,255,0)',
            paddingLeft: '4.45vh',
            marginBottom: '1.5vh',

            // transform: 'scale(2,0.2)',
          }}
        >
          <div
            style={{
              display: materialIdx[name] === k ? 'block' : 'none',
              borderLeft: '0.7vh solid #97BF55',
              height: '8.5vh',
              position: 'absolute',
              left: 0,
              // transform: 'scale(2,0.2)',
            }}
          ></div>
          <ul className={css(styles.productlist)} style={{}}>
            <li
              key={k}
              className={css(styles.flex)}
              onClick={() => {
                setMaterial(k)
                // if (materialIdx[name] === k) {
                //   console.log('material: ' + data.name)
                //   setCurrentProduct(data.name)
                // }
                setSelected(data)
                if (name === 'tuff_blocks') {
                  setView(JSON.parse(data.view))
                }
              }}
            >
              <img style={{ paddingLeft: '0.3vw' }} src={data.src} />
              <span
                style={{
                  paddingLeft: '24px',
                  fontSize: '2.2vh',
                  marginTop: '3.2vh',
                  transform: 'translateY(-50%)',
                }}
              >
                {data.name}
              </span>
            </li>
          </ul>
        </div>
      </>
    ))
  }
  return (
    <>
      {/* <CloseIcon
          onClick={() => {
            setHide(true)
            unselect && unselect()
          }}
          className={css(styles.close)}
        />
        {currentContent.hotspotImage?.url && (
          <img className={css(styles.image)} src={getFileUrl(currentContent.hotspotImage.url)} />
        )}
        <hr className={css(styles.line)}></hr> */}
      <div style={{ pointerEvents: 'auto', overflowX: 'visible' }}>
        <div className={css(styles.scroll)}>
          <Button />
        </div>
      </div>
      {/* <a.div style={spring} className={css(styles.container, className)}></a.div> */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    color: '#F2F2F2',
    position: 'absolute',
    fontFamily: 'Work Sans',
    textAlign: 'left',
    left: '80vh',
    top: '0',
    height: '91.5vh',
    color: 'white',
    width: '25vw',
    padding: '2vh 4.45vh 8vh 4.45vh',
    margin: '5vh 1vh 1vh 0vh',
    // borderRadius: '1.5vh',
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  productlist: {
    padding: '1vh 0vh 1vh 0vh',
    margin: '0',
    // marginLeft: '2vw',
    // backgroundColor: 'red',

    pointerEvents: 'auto',
  },
  image: {
    height: '5.8vh',
  },
  title: {
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: '2.5vh',
    marginTop: '2vh',
  },
  description: {
    fontSize: '1.8vh',
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  footerTitle: {
    fontSize: '1.9vh',
    textAlign: 'left',
    color: '#F2F2F2',
  },
  touch: {
    textDecoration: 'underline',
  },
  close: {
    height: '3vh',
    marginTop: '0.2vh',
    float: 'right',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  line: {
    margin: 0,
    transform: 'scale(2,0.1)',
    color: 'white',
    height: '0.04vh',
  },
  footer: {
    padding: '2vh 2vh 2vh 3vh',
  },
  footerline: {
    fontSize: '12px',
    color: '#f2f2f2',
  },
  footerline2: {
    fontFamily: 'Work Sans',
    fontSize: '14px',
    color: '#f2f2f2',
  },
  scroll: {
    pointerEvents: 'auto',
    // height: '40vh',
    padding: '1vh 0vh 1vh 0vh',
    borderRadius: 4,
    overflowY: 'auto',
    overflowX: 'visible',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '2.4vh',
      height: '2.4vh',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '6.4vh',
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
})
