import { useThree } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import { PMREMGenerator, UnsignedByteType } from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import night from './night.hdr'
import { useAppState } from '../../stores'

export const Skybox = () => {
  const { gl, scene } = useThree()
  const [name] = useState('sample.hdr')
  const useApp = useAppState()
  // useEffect(() => {
  //   if (page != 'showcase') setName(SkyboxMain)
  //   else if (page == 'showcase') {
  //     setName(SkyboxRooms[room])
  //   }
  // }, [page, room])

  useEffect(() => {
    if (useApp.room?.name) {
      let pmremGenerator = new PMREMGenerator(gl)
      pmremGenerator.compileEquirectangularShader()
      new RGBELoader()
        .setDataType(UnsignedByteType)
        // .setPath('./skybox/')
        .load(night, function (texture) {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture
          scene.environment = envMap
          texture.dispose()
          pmremGenerator.dispose()
        })

      return function cleanup() {
        pmremGenerator.dispose()
      }
    }
  }, [gl, scene, name, useApp.room])

  // useEffect(() => {}, [])
  return null
}
