import { useGLTF } from '@react-three/drei'
import { useEffect } from 'react'

function PreloadModel({ setCanLoad, loadModel, everythingLoaded }) {
  // const model = useGLTF(
  //   GPUTier.tier != null &&
  //     !loadModel &&
  //     (isMobile || GPUTier.tier <= 1
  //       ? '/glb/mobileGLB/model.gltf'
  //       : GPUTier.tier === null || GPUTier.tier >= 2
  //       ? '/glb/desktopGLB/model.gltf'
  //       : '/glb/mobileGLB/model.gltf')
  // )
  const mobileGlb = useGLTF('/glb/mobileGLB/model.gltf')
  const desktopGlb = useGLTF('/glb/desktopGLB/model.gltf')
  useEffect(() => {
    if (!everythingLoaded) {
      if (!loadModel) {
        setCanLoad(true)
      }
    }
  }, [mobileGlb, desktopGlb])
  return null
}

export default PreloadModel
