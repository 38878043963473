import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHud } from '../../../stores'

export const ContactUs = ({ setDisclaimer, disclaimer }) => {
  const { setEnquiryForm } = useHud()
  return (
    <>
      <button
        className={css(styles.button)}
        onClick={() => {
          setEnquiryForm(true)
          if (disclaimer) {
            setDisclaimer(false)
          }
        }}
      >
        Enquiry
      </button>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    // position: 'absolute',
    // inset: 0,
    // width: '100%',
    // height: '100%',
    // margin: '3vh 3vh 0 0',
    // backgroundColor: 'white',
    // borderRadius: '1vh',
    // cursor: 'pointer',
    // pointerEvents: 'auto',
    // fontFamily: 'Work Sans',
    // fontWeight: 500,
    // borderRadius: '2vh',
    // margin: 0,
    // boxSizing: 'border-box',
    // outline: 'white 2vh solid',
    // width: '9.4vh',
    // top: '3.20vh',
    // right: '10.80vh',
    // marginBottom: '3.25vh',
    // marginLeft: '0.5vh',
    // paddingLeft: '1vh',
    // paddingRight: '1vh',
    // lineHeight: '2vh',
    // fontSize: '1.8vh',
    background: 'red',
  },
  button: {
    background: '#ffffff',
    paddingLeft: '2vh',
    height: '3.8vh',
    paddingRight: '2vh',
    color: '#3D3D3D',
    // paddingTop: '1.5vh',
    // paddingBottom: '1.5vh',
    border: 'none',
    outline: 'none',
    borderRadius: '5vh',
    fontSize: '1.8vh',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    textAlign: 'center',
    pointerEvents: 'auto',

    ':hover': {
      cursor: 'pointer',
    },
  },
  close: {
    float: 'right',
    marginTop: '-0.8vh',
    height: '3vh',
    cursor: 'pointer',
  },
  enquirypara: {
    fontSize: '1.3vh',
    fontFamily: 'Work Sans',
    color: '#FFFFFF',
    marginTop: '5vh',
    marginBottom: '0',
    padding: '0vh 5vh 0vh 0vh',
  },
  container_form: {
    position: 'absolute',
    top: '-30vh',
    right: '5vw',
    background: 'red',
    width: '50%',
    height: '50%',
    // pointerEvents: 'auto',
    // color: '#F2F2F2',
    // position: 'absolute',
    // top: '8.6vh',
    // right: '5vw',
    // fontFamily: 'Work Sans',
    // height: 'auto',
    // color: 'white',
    // padding: '5vh 5vh 5vh 5vh',
    // backgroundColor: '#222222',
    // zIndex: 100,
  },
})
