import React from 'react'
import { useHud } from '../../stores'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'
import { CloseIcon } from './CloseIcon'
import { isMobileOnly } from 'react-device-detect'

export const Disclaimer = (props) => {
  return (
    <a.div style={props.style} className={css(styles.disclaimer)} id="disclaimer">
      <div className={css(styles.flex)}>
        <h1 className={css(styles.heading)}>DISCLAIMER</h1>
        <CloseIcon className={css(styles.close)} onClick={props.closeButton} />
      </div>
      <div className={css(styles.scrollDisclaimer)}>
        <p className={css(styles.desc)}>
          The artistic works contained in this website are in the form of a 3D environment with 3D
          assets, and are meant only for representation purposes{' '}
        </p>
        <p className={css(styles.desc)}>
          The products are digitally enhanced for representational purpose. The shown views are
          indicative and artistic representations only and are not to be considered a part of any
          actual deliverables. The specifications and extent/ number /variety of the products
          displayed hereunder are indicative and are liable to change at the sole discretion of the
          company.
        </p>
        <p className={css(styles.desc)}>
          The artistic works, images, information on this website are provided in good faith and on
          an “AS IS” basis without any warranty, express, implied, or otherwise including, but not
          limited to, warranties of merchantability, non-infringement, or fitness for any particular
          purpose. Some jurisdictions do not allow for the exclusion of implied warranties, so the
          above exclusions may not apply to you. It is your responsibility to evaluate the accuracy,
          completeness of any information provided herein. Godrej & Boyce Mfg. Co. Ltd. may make any
          other changes to the site, the materials and the products, programs, services or prices
          (if any) described in the site at any time without notice.
        </p>
        <p className={css(styles.desc)}>
          All information contained herein is distributed with the understanding that the Company,
          authors, publishers and distributors are not rendering legal or other professional advice
          or opinions on specific facts or matters and accordingly assume no liability whatsoever in
          connection with its use. In no event shall Godrej & Boyce Mfg. Co. Ltd and its related,
          affiliated, and subsidiary companies be held liable for any direct, indirect, special,
          incidental or consequential damages arising out of(i) the reliance or use of the
          information provided on this website or (ii) the access to this website or any weblink
          posted herein
        </p>
        <p className={css(styles.desc)}>
          While efforts are made to keep the website smooth and running, we strongly recommend that
          you perform a virus check at your end prior to accessing any weblink.
        </p>
        <p style={{ marginBottom: '7vh' }} className={css(styles.desc)}>
          While efforts are made to keep the website smooth and running, we strongly recommend that
          you perform a virus check at your end prior to accessing any weblink.
        </p>
      </div>
    </a.div>
  )
}
export const DisclaimerIcon = (props) => {
  const { enquiryForm, setEnquiryForm } = useHud()
  return (
    <>
      <a.div
        className={css(styles.disclaimerButton)}
        onClick={() => {
          if (enquiryForm) {
            setEnquiryForm(false)
          }
          props.set(!props.show)
        }}
      >
        DISCLAIMER
      </a.div>
    </>
  )
}

const styles = StyleSheet.create({
  disclaimerButton: {
    // position: 'absolute',
    color: '#ffffff',
    fontSize: '1.35vh',
    marginRight: '1%',
    fontFamily: 'Work Sans',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    // top: '3.20vh',
    // right: '3.20vh',
  },
  scrollDisclaimer: {
    overflow: `${isMobileOnly ? 'auto' : 'auto'}`,
    height: `${isMobileOnly ? '75vh' : '80vh'}`,
    paddingRight: '0.8vh',
    '::-webkit-scrollbar': {
      width: `${isMobileOnly ? '4vh' : '2.0vh'}`,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#AAAAAA',
      borderRadius: '0.5vw',
      border: '5px solid transparent',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
  },
  disclaimer: {
    width: '26vw',
    backgroundColor: 'rgba(0,0,0,0.95)',
    position: 'absolute',
    right: 0,
    // right: '0.6vw',

    top: '8.7vh',
    color: 'white',
    fontFamily: 'Work Sans',
    boxSizing: 'border-box',
    padding: '4.4vh',
    pointerEvents: 'auto',
    zIndex: 100,
  },
  flex: {
    display: 'flex',
    // marginTop: '3vh',
    justifyContent: 'space-between',
    height: '2.2vh',
    marginBottom: '1vh',
    // background: 'red',
  },
  heading: {
    fontSize: '1.8vh',
    fontWeight: 600,
    lineHeight: '2.6vh',
    margin: 0,
  },

  desc: {
    // marginTop: '4vh',
    fontSize: '1.8vh',
    lineHeight: '2.3vh',
    fontWeight: 300,
    textAlign: 'justify',
  },
  close: {
    cursor: 'pointer',
    transform: 'scale(1.4) translateY(-20%)',
  },
})
