import React, { useState, useRef } from 'react'
import './Accordion.css'

export const KeyValue = ({ pairs }) => {
  return pairs
    .split(/\r?\n/)
    ?.filter((x) => x != '')
    .map((pair) => {
      let idk = pair.split(':')
      return (
        <p className="accordion_text" style={{ marginTop: '1vh', marginBottom: 0 }}>
          <b style={{ color: 'white' }}> {idk[0]}</b>
          {idk[1] ? ': ' + idk[1] : ''}
        </p>
      )
    })
}
export const Accordion = (props) => {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [setRotate, setRotateState] = useState('accordion_icon')

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`)
    setRotateState(setActive === 'active' ? 'accordion_icon rotate1' : 'accordion_icon rotate')
  }

  return (
    <div className="accordion_section">
      <button className={`accordion active`} onClick={toggleAccordion}>
        <p className="accordion_title" style={{ margin: 0 }}>
          {props.title}
        </p>
        {/* <Arrow className={`${setRotate}`} width={'1.9vh'} fill={'#fff'} /> */}
      </button>
      <div ref={content} className="accordion_content">
        <ul className="accordion_list" style={{ lineHeight: '150%' }}>
          {props.content
            ?.split(/\r?\n/)
            ?.filter((x) => x != '')
            .map((benefit, index) => {
              return (
                <li key={index} className="accordion_text">
                  {benefit}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
