import { Suspense } from 'react'
// import { Camera } from '../core/3d/Camera'
import { MainScene } from './MainScene'
import { Sky } from './Sky'

export const Environment = ({ onLoaderDone }) => {
  return (
    <>
      {/* {status && ( */}
      <Suspense fallback={null}>
        <MainScene onLoaderDone={onLoaderDone} />
        <Sky />
      </Suspense>
      {/* )} */}

      {/* <Camera /> */}
      {/* <Box /> */}
    </>
  )
}
