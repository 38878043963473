import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useLocation } from 'wouter'
import { useAppState, useHud } from '../../../stores'
import { ContactUs } from './ContactUs'
import { Logo } from '../../assets/Logo'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { colors } from '../../../../../theme'
import { Disclaimer, DisclaimerIcon } from '../../assets/Disclaimer'
import Form from './Form'
// import { CloseIcon } from '../../assets/CloseIcon'
import { isMobileOnly } from 'react-device-detect'

export const NavBar = ({ rooms }) => {
  const [location, setLocation] = useLocation()
  // const [active, setActive] = useState(false)
  const {
    currentContent: { name },
    room,
  } = useAppState()

  const { allRooms } = useAppState()
  const [active, setActive] = useState()
  const { setEnquiryForm } = useHud()

  const spring = useSpring({
    transform: name == 'base' ? 'translateY(56vh)' : 'translateY(-0.2vh)',
    // opacity: name == 'base' ? 0 : 1,
    // backgroundColor: name == 'base' ? 'rgba(0, 0, 0, 0.2)' : 'none',
    config: config.slow,
  })
  const startSpring = useSpring({
    // transform: name == 'base' ? 'translateY(67vh)' : 'translateY(-0.2vh)',
    transform: name == 'base' ? 'translateY(61vh)' : 'translateY(-0.2vh)',
    opacity: name == 'base' ? 1 : 0,
    // delay: 800,
    config: config.slow,
  })

  const { opacity } = useSpring({
    opacity: active ? 1 : 0,
  })
  const [show, set] = useState(false)

  const style = useSpring({
    transform: !show ? 'translateX(140%)' : 'translateX(0%)',
  })

  useEffect(() => {
    const unsub = useHud.subscribe(
      (paw) => {
        setActive(paw)
      },
      (state) => state.enquiryForm
    )
    return function cleanup() {
      unsub()
    }
  }, [active])

  return (
    <>
      <a.div className={css(styles.categoryContainer)} style={startSpring} id="baseContainer">
        {allRooms.map((category, index) => {
          if (category.name === 'base') return
          return (
            <>
              <div
                className={css(styles.items01)}
                style={{
                  pointerEvents: name === 'base' ? 'auto' : 'none',
                  borderRight: `${
                    index === allRooms.length - 1 ? 0 : '0.2vh solid rgba(255, 255, 255, 0.5)'
                  }`,
                }}
                onClick={() => setLocation(`/${category.name}`)}
                key={index}
              >
                {category.title.toUpperCase()}
              </div>
            </>
          )
        })}
      </a.div>
      <div
        className={css(styles.container)}
        style={{ background: name == 'base' ? 'none' : 'rgba(0, 0, 0, 0.5)' }}
      >
        <Logo hidden className={{}} />
        {allRooms.map((category, index) => {
          if (category.name === 'base') return
          return (
            <>
              <a.div className={css(styles.categoryContainer01)} style={spring} key={index}>
                <div
                  className={css(styles.selector)}
                  style={{
                    backgroundColor:
                      category.name === room?.name ? colors.godrejGreen : 'rgba(0,0,0,0)',
                  }}
                ></div>
                <div
                  style={{
                    borderTop: `0.8vh solid ${
                      category.name === room?.name ? colors.godrejGreen : 'rgba(0,0,0,0)'
                    }`,
                    opacity: category.name === room?.name || name === 'base' ? 1 : 0.9,
                    color: category.name === room?.name ? 'white' : 'rgba(255,255,255,0.7)',
                  }}
                  className={css(styles.item)}
                  onClick={() => setLocation(`/${category.name}`)}
                >
                  <div style={{ marginTop: '-0.8vh' }}>{category.title.toUpperCase()}</div>
                </div>
              </a.div>
            </>
          )
        })}

        <div className={css(styles.rightButtons)}>
          <ContactUs active={active} setActive={setActive} setDisclaimer={set} disclaimer={show} />
          <DisclaimerIcon set={set} show={show} />
        </div>
      </div>

      {/* Enquiry Form */}
      <a.div
        style={{ opacity, pointerEvents: active ? 'auto' : 'none' }}
        className={css(styles.container_form)}
      >
        <div
          style={{
            position: 'absolute',
            // background: 'red',
            top: 0,
            right: '17vh',
            transform: 'translateY(-1.5vh)',
          }}
        >
          <svg
            width="3vh"
            height="100%"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.63712 0.800616L1.04727 16.0781C0.578769 16.9114 1.18092 17.9407 2.13685 17.9407L20.032 17.9407C21.0073 17.9407 21.6069 16.8735 21.0996 16.0405L11.7943 0.763005C11.2972 -0.0530851 10.1054 -0.032307 9.63712 0.800616Z"
              fill="black"
              fillOpacity="0.85"
            />
          </svg>
        </div>

        <Form setActive={setActive} setEnquiryForm={setEnquiryForm} />
      </a.div>

      {/* Disclaimer Component */}
      <Disclaimer style={style} closeButton={() => set(false)} />
    </>
  )
}

const styles = StyleSheet.create({
  rightButtons: {
    marginRight: '1%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '10.8%',
    // background: 'red',
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // paddingTop: '1.5%',
    // paddingLeft: '2%',
    // paddingRight: '2%',
    // paddingBottom: '1.5%',
    padding: '1.5vh 2.2vh',
    // flex: '1 1 auto',

    // margin: 5,
    // padding: '2%',
    // height: '1.5%',
    // padding: '5%',
    // pointerEvents: 'auto',
    // padding: '1.5%',
    color: 'white',
    // background: 'blue',

    textAlign: 'center',
    // verticalAlign: 'middle',
    fontSize: '1.8vh',
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    // pointerEvents: 'auto',
    // flexBasis: '60%',

    // paddingRight: '2%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  categoryContainer01: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryContainer: {
    display: 'flex',
    // justifyContent: 'space-between',
    position: 'absolute',
    left: '7%',
    // transform: 'translateY(20vh)',
    // top: '2%',
    // display: 'flex',
    alignItems: 'center',
    // flexWrap: 'wrap',
    // width: 1040,
    background: 'rgba(0, 0, 0, 0.6)',
    minHeight: '8.5vh',
    color: 'white',
    padding: '1.3vh',
  },
  items01: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // paddingTop: '1.5%',
    // paddingLeft: '2%',
    // paddingRight: '2%',
    // paddingBottom: '1.5%',
    padding: '1.5vh 2.2vh',
    // flex: '1 1 auto',

    // margin: 5,
    // padding: '2%',
    // height: '1.5%',
    // padding: '5%',
    // pointerEvents: 'auto',
    // padding: '1.5%',
    color: '#ffffff',
    // background: 'blue',

    textAlign: 'center',
    // verticalAlign: 'middle',
    fontSize: '1.8vh',
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    // pointerEvents: 'auto',
    // flexBasis: '60%',

    // paddingRight: '2%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  closeButton: {
    float: 'right',
    marginTop: '-2vh',
    height: '3vh',
    cursor: 'pointer',
  },
  container_form: {
    // position: 'absolute',
    // top: '-30vh',
    // right: '5vw',
    // background: 'red',
    // width: '50%',
    // height: '50%',
    color: '#F2F2F2',
    position: 'absolute',
    top: '8.6vh',
    right: 0,
    textAlign: 'center',
    // right: '5vw',
    fontFamily: 'Work Sans',
    height: `${isMobileOnly ? '75vh' : 'auto'}`,
    overflowY: `${isMobileOnly ? 'auto' : 'none'}`,
    color: 'white',
    width: '60vh',
    padding: '5vh 3.5vh 5vh 5vh',
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    zIndex: 200,
  },
  enquirypara: {
    fontSize: '1.5vh',
    textAlign: 'left',
  },
  container: {
    // width: '80%',
    // borderTop: '0.2vh solid white',
    // borderBottom: '0.2vh solid white',
    display: 'flex',
    position: 'absolute',
    // backgroundColor: 'rgba(24.3%,24.31%,24.31%,0.21)',
    // right: '50%',
    // transform: 'translateX(50%)',
    // background: 'rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
    width: '100%',
    zIndex: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '8.5vh',
    top: 0,
    left: 0,
  },
  item: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // textAlign: 'center',
    // paddingTop: '2%',
    // paddingLeft: '1%',
    // paddingRight: '1%',
    // paddingBottom: '2%',
    // flex: '1 1 auto',
    // margin: 5,
    // padding: '2%',
    // height: '1.5%',
    // padding: 10,
    // padding: '1.5%',
    // color: 'white',
    // background: 'blue',

    // textAlign: 'center',
    // verticalAlign: 'middle',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    margin: 0,
    // marginTop: '-0.5vh',
    lineHeight: '8.5vh',
    fontSize: '1.8vh',
    // whiteSpace: 'nowrap',
    pointerEvents: 'auto',
    // flexBasis: '60%',

    // paddingRight: '2%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  desc: {
    fontWeight: 300,
    fontSize: 16,
    margin: 0,
    marginTop: '0.6vh',
    lineHeight: '3vh',
    '@media (max-width:950px)': {
      fontSize: '2.6vh',
    },
  },
  logo: {},
})
