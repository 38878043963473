import { useSpring } from '@react-spring/core'
import { StyleSheet, css } from 'aphrodite'
import React from 'react'
import { useAppState } from '../../../stores'
import { animated as a } from '@react-spring/web'

export const SubCategoryName = () => {
  const { currentContent } = useAppState()
  const { opacity } = useSpring({
    opacity:
      currentContent.type === 'subcat' &&
      currentContent.name != 'recycled_concrete' &&
      currentContent.name != 'walling_and_paving'
        ? 1
        : 0,
  })
  return currentContent.type === 'subcat' && currentContent.title ? (
    <a.div style={{ opacity }} className={css(styles.box)}>
      {
        currentContent.title
        // .split('_')
        // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        // .join(' ')
      }
    </a.div>
  ) : null
}

const styles = StyleSheet.create({
  box: {
    position: 'absolute',
    left: '0vh',
    bottom: '1vh',
    fontFamily: 'Helvetica',
    fontSize: '2.3vh',
    color: 'white',
    padding: '2.5vh 4.5vh 2.5vh 4.5vh',
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
})
