import { useFrame, useThree, extend, useLoader } from '@react-three/fiber'
import React, { useMemo, useRef } from 'react'
extend({ Water })
import { Water } from 'three-stdlib'
import * as THREE from 'three'

function WaterShader() {
  const ref = useRef()
  const gl = useThree((state) => state.gl)
  const waterNormals = useLoader(THREE.TextureLoader, '/textures/water/waternormals.webp')
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 1000), [])
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding,
    }),
    [waterNormals]
  )
  // useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta))
  return (
    <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} position={(100, 50, -10)} />
  )
  // return <div>WaterShader</div>
}

export default WaterShader
