import { useDetectGPU } from '@react-three/drei'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useQualitySwitch } from '../stores'

function Quality() {
  const GPUTier = useDetectGPU()
  const qualitSwitch = useQualitySwitch()

  useEffect(() => {
    if (GPUTier.tier < 3 || isMobile) {
      qualitSwitch.setQuality('low-res')
    } else if (GPUTier.tier >= 3) {
      qualitSwitch.setQuality('high-res')
    }
  }, [])
  return null
}

export default Quality
