import { useSpring } from '@react-spring/core'
import React from 'react'
import { useAppState } from '../../../stores'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'

export const Gradient = () => {
  const { currentContent } = useAppState()

  const gradient = useSpring({
    opacity:
      currentContent.name === 'Ready_Mix_Concrete' ||
      currentContent.name === 'Walling_And_Paving_Solutions' ||
      currentContent.name === 'base' ||
      currentContent.name === 'recycled_concrete' ||
      currentContent.name === 'walling_and_paving'
        ? // !currentContent.roomName
          1
        : 0,
  })

  return (
    <>
      <a.div
        style={{
          ...gradient,
          // pointerEvents: currentContent.roomName || designerEnabled ? 'none' : 'auto',
          pointerEvents: 'none',
        }}
        className={css(styles.gradient)}
      ></a.div>
    </>
  )
}

const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(19, 18, 18, 0.35)',
    // background:
    // 'linear-gradient(180deg, rgba(19, 18, 18, 0.85) 4.09%, rgba(101, 101, 101, 0) 112.14%)',
    zIndex: 1,
  },
})
