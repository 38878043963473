import { useSpring } from '@react-spring/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAppState } from '../../stores'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'
import { designerEnabled } from '../../settings'
import { SingleProductCard } from './components/SingleProductCard'
import { Concrete, Walling } from './components/CategoryPageIcons'
import CategoriesList from './components/CategoriesList'
import TuffLogo from '../assets/TuffLogo'

export const CategoryPage = () => {
  const { currentContent, room } = useAppState()
  const [localState, setLocal] = useState({ ...currentContent })
  const [suspend, setSuspend] = useState(false) // fades out before changing the content
  // const [catBypass, setCatBypass] = useState(false)

  const dontShow = useMemo(() => {
    return (
      (currentContent.roomName &&
        !(
          (
            currentContent.name === 'recycled_concrete' ||
            currentContent.name === 'walling_and_paving'
          ) // its a hotspot but not one of these two
        )) ||
      suspend
    )
  }, [currentContent, room, suspend])

  const { opacity } = useSpring({
    opacity: dontShow ? 0 : 1,
  })

  const timeout = useRef()
  useEffect(() => {
    setSuspend(true)
    timeout.current = setTimeout(() => {
      setSuspend(false)
      setLocal({ ...currentContent })
    }, [900])

    return () => clearTimeout(timeout.current)
  }, [currentContent])

  // const productCardContent = useMemo(() => {
  //   if(dontShow)return {}
  //   return {
  //     title:currentContent.title,
  //     desc:currentContent:desc
  //   }
  // },[currentContent])
  return (
    <>
      {localState.name === 'Ready_Mix_Concrete' ||
      localState.name === 'Walling_And_Paving_Solutions' ||
      localState.name === 'base' ||
      localState.name === 'recycled_concrete' ||
      localState.name === 'walling_and_paving' ? (
        <a.div
          className={css(styles.container)}
          style={{
            opacity,
            margin: '15vh',
            pointerEvents:
              localState.roomName ||
              localState.name === 'Pre_Fabricated_Concrete' ||
              designerEnabled
                ? 'none'
                : 'auto',
            width:
              localState.name === 'base' ||
              localState.name === 'recycled_concrete' ||
              localState.name === 'walling_and_paving' ||
              localState.name === 'Walling_And_Paving_Solutions'
                ? '90.4vh'
                : '50%',
          }}
        >
          {localState.name === 'base' && <TuffLogo />}

          <p
            className={css(styles.title)}
            style={{ display: localState.name != 'base' ? 'block' : 'none' }}
          >
            {localState.title}
          </p>
          <h1 className={css(styles.subTitle)}>
            {localState.name === 'walling_and_paving'
              ? localState.benefits
              : localState.name === 'recycled_concrete'
              ? 'RECOVER. RECYCLE. REBUILD.'
              : localState.subTitle}
          </h1>
          <p className={css(styles.desc)}>{localState.desc}</p>

          <CategoriesList state={localState} />

          {localState.name === 'base' && (
            <p className={css(styles.desc, styles.tip)}>Pick a category to learn more</p>
          )}
          <div style={{ height: '10.3vh', marginTop: '5vh' }}>
            {localState.name === 'Ready_Mix_Concrete' && <Concrete />}
            {localState.name === 'Walling_And_Paving_Solutions' && <Walling />}
            {localState.name === 'walling_and_paving' && <Concrete />}
            {localState.name === 'recycled_concrete' && <Walling />}
          </div>
        </a.div>
      ) : (
        localState.name &&
        !localState.roomName && (
          <SingleProductCard
            style={{
              opacity,
              pointerEvents: localState.roomName || designerEnabled ? 'none' : 'auto',
            }}
            showAnyway
            className={styles.none}
            currentContent={localState}
            imgText={localState.subTitle}
            noAnim
          />
        )
      )}
    </>
  )
}
let ratio = 7.5739
const getvh = (asd) => String(asd / ratio) + 'vh'
const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(19, 18, 18, 0.8) 4.09%, rgba(101, 101, 101, 0) 112.14%)',
    zIndex: 0,
  },
  none: {},
  container: {
    fontFamily: 'Work Sans',
    color: 'white',
    // maxWidth: '40%',
    marginTop: getvh(130),
    // background: 'red',
  },
  title: {
    fontWeight: 300,
    fontSize: getvh(16),
    // lineHeight: '2.7vh',
    margin: 0,
    marginTop: '1vh',
    '@media (max-width:950px)': {
      fontSize: '2.6vh',
    },
  },
  subTitle: {
    fontWeight: 600,
    fontSize: getvh(64),
    margin: 0,
    marginTop: '0.6vh',
    marginLeft: '-0.3vw',
    lineHeight: getvh(62),
    // '@media (max-width:950px)': {
    //   fontSize: '10.9vh',
    // },
  },
  desc: {
    fontWeight: 300,
    fontSize: getvh(16),
    margin: 0,
    marginTop: '0.6vh',
    lineHeight: '3vh',
    '@media (max-width:950px)': {
      fontSize: '2.6vh',
    },
  },
  tip: {
    fontWeight: 400,
    marginTop: '13.5vh',
    // marginLeft: '2.7vw',
    fontSize: getvh(13),
  },
})
