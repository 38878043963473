import React, { useEffect } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useState } from 'react'
import emailjs from '@emailjs/browser'
import { CityList, productList } from '../../../products'
import { useHud } from '../../../stores'
import { CloseIcon } from '../../assets/CloseIcon'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'

export const Form = ({ setActive, setEnquiryForm }) => {
  // const [Active, Inactive] = useState('Easy Tuff')
  const [currProd, setCurrProd] = useState(null)
  const { enquiryForm } = useHud()
  // const [prodDefaultValue, setProdDefaultValue] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const [values, setValues] = useState({
    email: '',
    userName: '',
    phoneNumb: '',
    section: 'Enviro Tuff',
    message: '',
    city: 'Mumbai',
  })

  useEffect(() => {
    const unsub = useHud.subscribe(
      (paw) => {
        setCurrProd(paw)
        // console.log('prod paw: ' + paw)
      },
      (state) => state.currentProduct
    )
    return function cleanup() {
      unsub()
    }
  }, [currProd])

  useEffect(() => {
    let mySelect = document.getElementById('mySelect')
    // console.log('outside i value: ')
    for (var i, j = 0; (i = mySelect.options[j]); j++) {
      if (i.value === currProd) {
        mySelect.selectedIndex = j
        break
      }
    }
    // setProdDefaultValue(currProd)
  }, [enquiryForm, currProd])

  const handleSubmit = (e) => {
    e.preventDefault()

    emailjs.send('service_dg3qru9', 'template_q8cyk4j', values, 'qc3a663JyEiqvbCoj').then(
      (response) => {
        // console.log('Success: ' + response)
        if (response.status === 200) {
          setSubmitted(true)
        }
      },
      (error) => {
        setSubmitted(true)
        // console.log('Failed ' + error)
      }
    )
    e.target.reset()
  }
  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }))
  }

  const charAllow = (e) => {
    if (e.target.value.match(/[a-zA-Z]+$/)) {
      setValues((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }))
    } else {
      return false
    }
  }

  const numbAllow = (e) => {
    if (e.target.value.match(/[0-9]+$/)) {
      setValues((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }))
    } else {
      return false
    }
  }
  // function validateAlpha() {
  //   //
  //   let name = document.getElementById('uName')
  //   console.log('name: ' + name.value.match(/^[a-zA-Z]+$/))
  //   if (name.value.match(/^[a-zA-Z]+$/)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const spring = useSpring({
    from: {
      opacity: 0,
    },
    opacity: submitted ? 1 : 0,
    config: {
      duration: 2000,
    },
  })

  // console.log('prod: ' + values.section)

  return (
    <>
      <div>
        <CloseIcon
          onClick={() => {
            setActive(false)
            setEnquiryForm(false)
            document.getElementById('frm').reset()
          }}
          className={css(styles.closeButton)}
        />
      </div>
      <p className={css(styles.enquirypara)}>
        For all enquiries please send us an email using the form below
      </p>
      <div>
        <form className={css(styles.form)} onSubmit={handleSubmit} id="frm" name="frm">
          <input
            type="text"
            className={css(styles.input_text)}
            placeholder="Email"
            name="email"
            onChange={handleChange}
          />
          <input
            type="text"
            className={css(styles.input_text)}
            placeholder="Name"
            name="userName"
            onChange={charAllow}
            value={values.userName}
            id="uName"
            // onClick={() => {
            //   validateAlpha()
            // }}
          />
          <div className={css(styles.input_text)}>
            <div style={{ display: 'flex' }}>
              <label htmlFor="phone" style={{ opacity: 0.5 }}>
                +91
              </label>
              <input
                type="tel"
                className={css(styles.numb)}
                pattern="[1-9]{1}[0-9]{9}"
                // placeholder="+91"
                name="phoneNumb"
                value={values.phoneNumb}
                onChange={numbAllow}
                style={{
                  marginLeft: '2%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '2vh',
                  background: 'none',
                  // '-webkit-appearance': 'none',
                  // '-moz-appearance': 'none',
                  // appearance: 'none',
                  color: '#ffffff',
                }}
              />
            </div>
          </div>

          <select
            // value={values.city}
            className={css(styles.input_text)}
            style={{ color: 'white', whiteSpace: 'pre-wrap' }}
            onChange={handleChange}
            name="city"
            value={values.city}
          >
            {CityList.map((cityList, index) => {
              return (
                <option
                  style={{ color: 'black', whiteSpace: 'pre-wrap' }}
                  value={cityList}
                  key={index}
                >
                  {cityList}
                </option>
              )
            })}
          </select>
          <select
            value={values.section}
            className={css(styles.input_text)}
            style={{ color: 'white' }}
            onChange={handleChange}
            name="section"
            id="mySelect"
          >
            {/* {console.log('default value: ' + prodDefaultValue)} */}
            {productList.map((prodList, index) => {
              return (
                <option
                  style={{ color: 'black' }}
                  value={prodList}
                  key={index}
                  // selected={currProd === prodList}
                  // selected={currProd === prodList ? 'hello' : null}
                >
                  {/* {currProd === prodList ? currProd : null} */}
                  {prodList}
                </option>
              )
            })}
          </select>
          <textarea
            type="text"
            className={css(styles.input_text)}
            style={{ height: '144px', fontFamily: 'Work Sans' }}
            placeholder="Message"
            name="message"
            onChange={handleChange}
          />
          <div
            style={{
              color: 'white',
              fontSize: '1.8vh',
              fontFamily: 'Work Sans',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <a.div style={spring}>Your Message Sent Successfully!</a.div>
            <input
              type="submit"
              className={css(styles.submit)}
              value="Send"
              style={{ cursor: 'pointer' }}
            />
          </div>
        </form>
        <hr className={css(styles.line)}></hr>

        <p className={css(styles.infoTitle)}>Contact Info</p>
        <div
          style={{
            marginTop: '0.6vh',
            marginBottom: '0.6vh',
            fontSize: '1.9vh',
            textAlign: 'left',
          }}
        >
          <span>Email: </span> <span>construction@godrej.com</span>
        </div>
        <div style={{ fontSize: '1.9vh', textAlign: 'left' }}>
          <span>Ph no: </span> <span>+91 9920999991</span>
        </div>
      </div>
      {/*  */}
    </>
  )
}
const styles = StyleSheet.create({
  closeButton: {
    float: 'right',
    marginTop: '-2vh',
    height: '3vh',
    cursor: 'pointer',
  },
  enquirypara: {
    fontSize: '1.5vh',
    textAlign: 'left',
  },
  numb: {
    ':focus': {
      appearance: 'none',
      '::-webkit-appearance': 'none',
      '::-webkit-appearance': 'none',
    },
    appearance: 'none',
    '::-webkit-appearance': 'none',
    '::-webkit-appearance': 'none',
  },
  line: {
    margin: 0,
    transform: 'scale(1.2,0.2)',
    color: 'white',
    height: '0.04vh',
    marginTop: '1.2vh',
    // border: 0,
    // borderWidth: 0,
  },
  infoTitle: {
    fontSize: '2.1vh',
    textAlign: 'left',
    // background: 'blue',
    // margin: 0,
    // padding: 0
  },
  container: {
    // position: 'absolute',
    // width: '100%',
    // height: '100%',
    // margin: '3vh 3vh 0 0',
    backgroundColor: 'white',
    borderRadius: '1vh',
    cursor: 'pointer',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    margin: 0,
    // boxSizing: 'border-box',
    // outline: 'white 2vh solid',
    boxShadow: '0 0 0 1.1vh white',
    marginTop: '3.20vh',
    marginBottom: '3.25vh',
    marginLeft: '0.5vh',
    paddingLeft: '0.3vh',
    paddingRight: '0.3vh',
    lineHeight: '2vh',
    fontSize: '2vh',
  },
  close: {
    float: 'right',
  },
  container_form: {
    color: '#F2F2F2',
    fontFamily: 'Work Sans',
    height: 'auto',
    color: 'white',
    padding: '5vh 5vh 5vh 5vh',
    backgroundColor: '#222222;',
  },
  input_text: {
    padding: '1.2vh 1.3vh 1.2vh 1.3vh',
    // paddingTop: '1.2vh',
    // paddingLeft: '1.3vh',
    // paddingRight: 0,
    // paddingBottom: '1.2vh',
    // width: '32vh',
    width: '100%',
    color: '#ffffff',
    borderRadius: '1.5vh',
    margin: '1.2vh 0px',
    display: 'inline-block',
    border: 'none',
    fontSize: '2vh',
    background: 'rgba(242, 242, 242, 0.15)',
  },
  submit: {
    background: '#F2F2F2',
    borderRadius: '2.4vh',
    padding: '1.2vh 2.4vh',
    justifySelf: 'end',
    color: '#3D3D3D',
    fontSize: '2vh',
    marginTop: '1.2vh',
    fontWeight: 'bold',
  },
  form: {
    display: 'inline-grid',
    width: '100%',
  },
})

export default Form
